import React from 'react'
import classes from "./contact.module.css"
import Navbar from '../../components/Navbar/Navbar'
import ContactMain from '../../components/ContactMain/ContactMain'
import Footer from '../../components/Footer/Footer'

const Contact = ({desc, message, address, area, city, postcode, days, hours, phone, email, img, DBlogo,  DBemail, DBnumber}) => {
  return (
    <div>
      <Navbar scrollEvent={false}  DBlogo={DBlogo}/>
      <ContactMain desc={desc} message={message} address={address} area={area} city={city} postcode={postcode} days={days} hours={hours} phone={phone} img={img} email={email}/>
      <Footer   DBemail={DBemail} DBnumber={DBnumber}/>
      </div>
  )
}

export default Contact