import React from 'react'
import classes from "./homeCover.module.css"
import { FaArrowDown } from 'react-icons/fa';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

const HomeCover = ({arrow, homeTitle, imgs, homeStrap}) => {

    const PF = "https://kakushinapi.onrender.com/images/";


    const dummyPhotos = [



      

      "oldbatch1.jpg" ,
         "oldbatch2.jpg" ,
      "oldbatch3.jpg" ,
      "oldbatch4.jpg" ,
      "residential.jpg",
          "oldbatch5.jpg" ,
      "oldbatch6.jpg" ,
        "oldbatch7.jpg" 
     
     
     
     
       ]


    
  return (
    <div className={classes.container}>
      
         <div className={classes.abso}>
            
          
                <h1>{homeTitle}</h1>
            <p>Luxury -  London -  Interior Design</p>

           
        </div>
        { arrow && (
        <div className={classes.scrollIcon}><FaArrowDown/></div>)}
        <ImageCarousel images={imgs}/>

       
    </div>
  )
}

export default HomeCover