import React from 'react'
import classes from "./whatWeDoMain.module.css"

const WhatWeDoMain = ({title1, title2, title3, title4, desc1, desc2, desc3, desc4, img1, img2, img3, img4}) => {

    const PF = "https://kakushinapi.onrender.com/images/";


  return (
    <div className={classes.container}>

        <div className={classes.wrapper}>
            <div className={classes.row}>
                <div className={classes.txtBox}>
                    <h3>{title1}</h3>
                    <p>{desc1}</p>

                </div>
                <div className={classes.imgBox}>
                    <img src={PF + img1} alt="What we do, hard at work..." />

                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.txtBox}>
                    <h3>{title2}</h3>
                    <p>{desc2}</p>
                   

                </div>
                <div className={classes.imgBox}>
                    <img src={PF + img2} alt="The designs we make..." />

                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.txtBox}>
                    <h3>{title3}</h3>
                   
<p>{desc3}</p>


                </div>
                <div className={classes.imgBox}>
                    <img src={PF + img3} alt="What we do, hard at work..." />

                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.txtBox}>
                    <h3>{title4}</h3>
                    <p>
{desc4}
                    </p>

                </div>
                <div className={classes.imgBox}>
                    <img src={PF + img4} alt="What we do, hard at work..." />

                </div>
            </div>





         
        </div>



    </div>
  )
}

export default WhatWeDoMain