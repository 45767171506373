import React, { useState } from 'react';
import styles from './dropdown.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom'

const Dropdown = ({  title1, dropdownObject, fontColor, isOpen, propFunction }) => {




   /* const handleMouseLeave = (event) => {
      const { clientX, clientY } = event;
      const element = event.currentTarget;
      const rect = element.getBoundingClientRect();
      const { left, top, width, height } = rect;
    
      // Calculate the center coordinates of the element
      const centerX = left + width / 2;
      const centerY = top + height / 2;
    
      // Determine the direction based on mouse coordinates
      const isLeft = clientX < centerX;
      const isTop = clientY < centerY;
    
      if (isLeft && isTop) {
        // Mouse left in the top-left direction
        otherToggle();
      } else if (!isLeft && isTop) {
        // Mouse left in the top-right direction
        otherToggle();
      } else if (!isTop) {
        // Mouse left out of the bottom of the dropdownList
        lastToggle();
      }


    };

  const otherToggle = () => {
    setIsOpen(false);
  };

  */




  return (
    <div className={styles.dropdown}>
      <div className={styles.form}>
        <button className={styles.button} style={{color: fontColor}} >
          {title1}
          <FontAwesomeIcon
            icon={faChevronDown}
            
            className={`${styles.chevron} ${isOpen ? styles.active : ''}`}
          />
        </button>
      </div>
      <ul className={`${styles.dropdownList} ${isOpen ? styles.show : ''}`}  >
      {Array.isArray(dropdownObject) ? (
          dropdownObject.map((item, index) => (
            <li onClick={propFunction} key={index}>
             
              <Link to={item.link}>{item.title}</Link>
            </li>
          ))
        ) : (
          <li>No dropdown items found.</li>
        )}
      </ul>
    </div>
  );
};

export default Dropdown;