import React, { useEffect, useState } from 'react'
import classes from "./adminBlog.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../../util/fetchAPI'


const AdminBlog = ({ property, closeModal, fetchFunction }) => {

    const { token, user } = useSelector((state) => state.auth)



    const { id } = useParams()

    const navigate = useNavigate()
  
    const [newOwner, setNewOwner] = useState("")
  
    
    const [state, setState] = useState({

        
        createdAt
            :
            "2023-06-02T11:18:36.142Z",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",
        },
        strap:"",
       
        img
            :
            "",

            title: "",
        author: "",
        article
            :
            "",
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",

    })
    const [allAgents, setAllAgents] = useState([]);
   
   
    const [currentOwner, setCurrentOwner] = useState(state?.currentOwner)
    const [userDetail, setUserDetail] = useState({})
    const [photo, setPhoto] = useState("")
   
   





    useEffect(() => {
        const fetchAllAgents = async () => {
            const data = await request(`/blog/users`, 'GET')
            setAllAgents(data)
        }
        fetchAllAgents()
        
    }, [])


    const fetchDetails = async () => {
        try {
            const data = await request(`/blog/user/${newOwner}`, 'GET');
            setUserDetail(data);

        } catch (error) {
            console.error(error);
        }
    };


    const handleOption = (e) => {

        const selectedDataId = e.target.value;
        setNewOwner(selectedDataId)
    }



    useEffect(() => {

        if (newOwner !== "") {

            fetchDetails();


        }


    }, [newOwner]);











    useEffect(() => {
        setState({
            ...property,
        });
       
        setCurrentOwner(property.currentOwner);

        console.log(property);


    }, [property]);



    useEffect(() => {

        console.log(state);



    }, [state])






    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue})


    }
























    const handleListProperty = async (e) => {
        e.preventDefault();



        let filename = null;
      


        if (photo) {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename = state.img
        }


       


        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;



            if (userDetail.username !== undefined) {




                data = await request(`/blog/${property?._id}`, 'PUT', options, {
                    ...state,
                   
                    img: filename,
                    currentOwner: userDetail
                })

            }

            else {

                data = await request(`/blog/${property?._id}`, 'PUT', options, {
                    ...state,
                  
                    img: filename,
                   
                })

            }




            setUserDetail({})
            fetchFunction();

            alert("Your property has been successfully updated!")
            closeModal();

        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }









 




    return (

        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
            <h2>Edit Blog</h2>
            <form onSubmit={handleListProperty}>
                <div className={classes.inputs}>
                    <label>
                        Blog Title:
                        <input type="text" value={state.title} name='title' onChange={handleState} />
                    </label>
                    <label>
                        Strap line:
                        <input type="text" value={state.strap} name='strap' onChange={handleState} />
                    </label>
                    <label>
                        Author:
                        <input type="text" value={state.author} name='author' onChange={handleState} />
                    </label>
                    
                   

                </div>



                <h3 className={classes.scripTitle}>Property description:</h3>
                <textarea className={classes.scrip} type="text" value={state.article} name='article' onChange={handleState} />



                <h3 className={classes.scripTitle}>Property types:</h3>

                

                <h3 className={classes.scripTitle}>Photos:</h3>
                <div className={classes.types2}>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="photoBlogEdit">Main Image</label>
                        <input
                            id="photoBlogEdit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto(e.target.files[0]) }}
                        />

                        {photo === "" ? (


                            <img src={`https://kakushinapi.onrender.com/images/${state?.img}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                        ) : (
                            <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                        )}
                    </div>



                  



                    
                </div>


           


                <h3 className={classes.scripTitle}>The representing agent is: <br /> <span>{currentOwner?.username}</span></h3>


                <h5 className={classes.changeRep}>Change Representing Agent</h5>


                <select className={classes.options} onChange={handleOption}>

                    {allAgents.map((agent) => (

                        <option key={agent._id} value={agent._id}>{agent.username}</option>

                    ))}



                </select>


                <button className={classes.formButton2} type="submit">Update Property</button>
            </form>



        </div>


    )
}

export default AdminBlog