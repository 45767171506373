import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classes from "./adminWebsite.module.css"
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'

const AdminWebsite = () => {


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', updateViewportWidth);

        // Cleanup: Remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []); // Empty dependency array means the effect runs once after the initial render

    useEffect(() => {
        // This effect will run whenever the viewport width changes
        console.log('Viewport width changed:', viewportWidth);

        // You can add additional logic or actions here based on viewport changes

    }, [viewportWidth]); // Dependency array with viewportWidth









    //AUTHENTICATION

    const { token, user } = useSelector((state) => state.auth)



    //SETTING WHICH WEBSITE PANEL IS OPEN

    const [open, setOpen] = useState("");



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }





    // INITIALISING STATE AND FETCHING WEBSITE



    const [website, setWebsite] = useState([]);
    const [state, setState] = useState([]);


    const fetchWebsite = async () => {
        const data = await request(`/website/getAll`, 'GET');
        setWebsite(data);
        console.log(data);

    };


    useEffect(() => {

        fetchWebsite();

    }, [])




    useEffect(() => {


        if (website.length > 0) {
            console.log(website[0].ethosTitle)


            setState({
                ...website[0],
            });


            setPhotos(website[0]?.homeImgs);
            setPhotos2(website[0]?.galleryImgs)


        }



    }, [website])



    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })


    }





    // HANDLING AN UPDATE


    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [newPhotos, setNewPhotos] = useState([]);
    const [newPhotos2, setNewPhotos2] = useState([]);



    const [photo, setPhoto] = useState("");
    const [photo2, setPhoto2] = useState("");
    const [photo3, setPhoto3] = useState("");
    const [photo4, setPhoto4] = useState("");
    const [photo5, setPhoto5] = useState("");
    const [photo6, setPhoto6] = useState("");
    const [photo7, setPhoto7] = useState("");
    const [photo8, setPhoto8] = useState("");
    const [photo9, setPhoto9] = useState("");
    const [photo10, setPhoto10] = useState("");
    const [photo11, setPhoto11] = useState("");
    const [photo12, setPhoto12] = useState("");
    const [photo13, setPhoto13] = useState("");
    const [photo14, setPhoto14] = useState("");


    const [photo15, setPhoto15] = useState("");
    const [photo16, setPhoto16] = useState("");
    const [photo17, setPhoto17] = useState("");
    const [photo18, setPhoto18] = useState("");
    const [photo19, setPhoto19] = useState("");
    const [photo20, setPhoto20] = useState("");
    const [photo21, setPhoto21] = useState("");
    const [photo22, setPhoto22] = useState("");
    const [photo23, setPhoto23] = useState("");
    const [photo24, setPhoto24] = useState("");
    const [photo25, setPhoto25] = useState("");
    const [photo28, setPhoto28] = useState("");











    const handleListProperty = async (e) => {
        e.preventDefault();

        let dateStampedPhotos = [];
        let dateStampedPhotos2 = [];


        let filename = null;
        let filename2 = null;
        let filename3 = null;
        let filename4 = null;
        let filename5 = null;
        let filename6 = null;
        let filename7 = null;
        let filename8 = null;
        let filename9 = null;
        let filename10 = null;
        let filename11 = null;
        let filename12 = null;
        let filename13 = null;
        let filename14 = null;



        let filename15 = null;
        let filename16 = null;
        let filename17 = null;
        let filename18 = null;
        let filename19 = null;
        let filename20 = null;
        let filename21 = null;
        let filename22 = null;
        let filename23 = null;
        let filename24 = null;
        let filename25 = null;
        let filename28 = null;



       
        let initialPhotos = website[0].homeImgs;
        let initialPhotos2 =website[0].galleryImgs;

        // Current array of photos (new photos might have been added)


        // Function to determine if a photo is new (added to the array)
        function isPhotoEdited(photo, initialPhotos) {
            return !initialPhotos.includes(photo);
        }

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos = [...photos]; // Create a copy of photos2
        let editOldPhotos = [];
        let editOldPhotosIndices = [];
        let remainingOldPhotos = [];
        let remainingOldPhotosIndices = [];

        oldPhotos.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos)) {
                editOldPhotos.push(photo);
                editOldPhotosIndices.push(index);
            } else {
                remainingOldPhotos.push(photo);
                remainingOldPhotosIndices.push(index);
            }
        });

        let processedPhotos = [];

        if (editOldPhotos.length > 0) {
            const uploadPromises = editOldPhotos.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos) {
                editOldPhotos[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos = new Array(oldPhotos.length);

        // Place edited photos back into their original positions
        editOldPhotos.forEach((photo, i) => {
            combinedPhotos[editOldPhotosIndices[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos.forEach((photo, i) => {
            combinedPhotos[remainingOldPhotosIndices[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos = combinedPhotos;

        console.log(photos); // Output the final array of photos



        let oldPhotos2 = [...photos2]; // Create a copy of photos2
        let editOldPhotos2 = [];
        let editOldPhotosIndices2 = [];
        let remainingOldPhotos2 = [];
        let remainingOldPhotosIndices2 = [];

        oldPhotos2.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos2)) {
                editOldPhotos2.push(photo);
                editOldPhotosIndices2.push(index);
            } else {
                remainingOldPhotos2.push(photo);
                remainingOldPhotosIndices2.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPhotos2.length > 0) {
            const uploadPromises = editOldPhotos2.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos2.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPhotos2[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos2 = new Array(oldPhotos2.length);

        // Place edited photos back into their original positions
        editOldPhotos2.forEach((photo, i) => {
            combinedPhotos2[editOldPhotosIndices2[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos2.forEach((photo, i) => {
            combinedPhotos2[remainingOldPhotosIndices2[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos2 = combinedPhotos2;
      






        if (photo) {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename = state.logoImg
        }


        if (photo2) {
            const formData = new FormData()




            filename2 = crypto.randomUUID() + photo2.name
            formData.append("filename", filename2)
            formData.append("image", photo2)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename2 = state.homePortfolioImg
        }


        if (photo3) {
            const formData = new FormData()




            filename3 = crypto.randomUUID() + photo3.name
            formData.append("filename", filename3)
            formData.append("image", photo3)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename3 = state.aboutCoverImg
        }

        if (photo4) {
            const formData = new FormData()




            filename4 = crypto.randomUUID() + photo4.name
            formData.append("filename", filename4)
            formData.append("image", photo4)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename4 = state.aboutBottomImg
        }


        if (photo5) {
            const formData = new FormData()




            filename5 = crypto.randomUUID() + photo5.name
            formData.append("filename", filename5)
            formData.append("image", photo5)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename5 = state.weDoCoverImg
        }

        if (photo6) {
            const formData = new FormData()




            filename6 = crypto.randomUUID() + photo6.name
            formData.append("filename", filename6)
            formData.append("image", photo6)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename6 = state.weDoBottomImg
        }


        if (photo7) {
            const formData = new FormData()




            filename7 = crypto.randomUUID() + photo7.name
            formData.append("filename", filename7)
            formData.append("image", photo7)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename7 = state.weWorkCoverImg
        }


        if (photo8) {
            const formData = new FormData()




            filename8 = crypto.randomUUID() + photo8.name
            formData.append("filename", filename8)
            formData.append("image", photo8)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename8 = state.weWorkBottomImg
        }

        if (photo9) {
            const formData = new FormData()




            filename9 = crypto.randomUUID() + photo9.name
            formData.append("filename", filename9)
            formData.append("image", photo9)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename9 = state.ethosCoverImg
        }


        if (photo10) {
            const formData = new FormData()




            filename10 = crypto.randomUUID() + photo10.name
            formData.append("filename", filename10)
            formData.append("image", photo10)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename10 = state.ethosBottomImg
        }

        if (photo11) {
            const formData = new FormData()




            filename11 = crypto.randomUUID() + photo11.name
            formData.append("filename", filename11)
            formData.append("image", photo11)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename11 = state.galleryBottomImg
        }


        if (photo12) {
            const formData = new FormData()




            filename12 = crypto.randomUUID() + photo12.name
            formData.append("filename", filename12)
            formData.append("image", photo12)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename12 = state.caseStudiesCoverImg
        }

        if (photo13) {
            const formData = new FormData()




            filename13 = crypto.randomUUID() + photo13.name
            formData.append("filename", filename13)
            formData.append("image", photo13)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename13 = state.caseStudiesBottomImg
        }

        if (photo14) {
            const formData = new FormData()




            filename14 = crypto.randomUUID() + photo14.name
            formData.append("filename", filename14)
            formData.append("image", photo14)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename14 = state.contactImg
        }


        if (photo15) {
            const formData = new FormData()




            filename15 = crypto.randomUUID() + photo15.name
            formData.append("filename", filename15)
            formData.append("image", photo15)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename15 = state.homeIntroImg
        }




        if (photo16) {
            const formData = new FormData()




            filename16 = crypto.randomUUID() + photo16.name
            formData.append("filename", filename16)
            formData.append("image", photo16)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename16 = state.aboutIntroImg
        }


        if (photo17) {
            const formData = new FormData()




            filename17 = crypto.randomUUID() + photo17.name
            formData.append("filename", filename17)
            formData.append("image", photo17)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename17 = state.ethosIntroImg
        }

        if (photo18) {
            const formData = new FormData()




            filename18 = crypto.randomUUID() + photo18.name
            formData.append("filename", filename18)
            formData.append("image", photo18)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename18 = state.weWorkIntroImg
        }

        if (photo19) {
            const formData = new FormData()




            filename19 = crypto.randomUUID() + photo19.name
            formData.append("filename", filename19)
            formData.append("image", photo19)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename19 = state.weDoImg1
        }


        if (photo20) {
            const formData = new FormData()




            filename20 = crypto.randomUUID() + photo20.name
            formData.append("filename", filename20)
            formData.append("image", photo20)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename20 = state.weDoImg2
        }

        if (photo21) {
            const formData = new FormData()




            filename21 = crypto.randomUUID() + photo21.name
            formData.append("filename", filename21)
            formData.append("image", photo21)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename21 = state.weDoImg3
        }

        if (photo22) {
            const formData = new FormData()




            filename22 = crypto.randomUUID() + photo22.name
            formData.append("filename", filename22)
            formData.append("image", photo22)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename22 = state.weDoImg4
        }




        if (photo23) {
            const formData = new FormData()




            filename23 = crypto.randomUUID() + photo23.name
            formData.append("filename", filename23)
            formData.append("image", photo23)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename23 = state.portfolioImg1
        }


        if (photo24) {
            const formData = new FormData()




            filename24 = crypto.randomUUID() + photo24.name
            formData.append("filename", filename24)
            formData.append("image", photo24)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename24 = state.portfolioImg2
        }


        if (photo25) {
            const formData = new FormData()




            filename25 = crypto.randomUUID() + photo25.name
            formData.append("filename", filename25)
            formData.append("image", photo25)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename25 = state.portfolioImg3
        }



        if (photo28) {
            const formData = new FormData()




            filename28 = crypto.randomUUID() + photo28.name
            formData.append("filename", filename28)
            formData.append("image", photo28)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename28 = state.ogImg
        }


















        try {


            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;

            data = await request(`/website/${website[0]?._id}`, 'PUT', options, {
                ...state,


                galleryImgs: finalPhotos2,
                homeImgs: finalPhotos,





                logoImg: filename,
                homePortfolioImg: filename2,
                aboutCoverImg: filename3,
                aboutBottomImg: filename4,
                weDoCoverImg: filename5,
                weDoBottomImg: filename6,
                weWorkCoverImg: filename7,
                weWorkBottomImg: filename8,
                ethosCoverImg: filename9,
                ethosBottomImg: filename10,
                galleryBottomImg: filename11,
                caseStudiesCoverImg: filename12,
                caseStudiesBottomImg: filename13,
                contactImg: filename14,
                ogImg: filename28,
                homeIntroImg: filename15,
                aboutIntroImg: filename16,
                ethosIntroImg: filename17,
                weWorkIntroImg: filename18,
                weDoImg1: filename19,
                weDoImg2: filename20,
                weDoImg3: filename21,
                weDoImg4: filename22,
                portfolioImg1: filename23,
                portfolioImg2: filename24,
                portfolioImg3: filename25



            })






            setNewPhotos([]);
            setNewPhotos2([]);


            fetchWebsite();
            setOpen("")

            alert("The website has been successfully updated!")


        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }




    const handleUploadPhotoEdit = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);


    };

    const handleUploadPhotoEdit2 = (e) => {
        const files = Array.from(e.target.files);

        setPhotos2([...photos2, ...files]);


    };


    const deletePhoto = (photo) => {

        setNewPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };

    const deletePhoto2 = (photo) => {

        setNewPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
        setPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };











    const processObject = (object) => {


        let newArray = [];

        for (let key in object) {

            newArray.push(key)


        }

        return newArray;

    }





    const onDragEnd2 = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos2);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos2(updatedItems);
    };


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos(updatedItems);
    };




    return (
        <div className={classes.listPropertyWrapper} style={open === "" ? { width: viewportWidth < 600 ? "65%" : "40%" } : open !== "portfolio" ? { width: "80%" } : { width: viewportWidth < 600 ? "70%" : "50%" }} onClick={(e) => e.stopPropagation()}>
            <h2>EDIT WEBSITE</h2>
            <form onSubmit={handleListProperty}>


                <h3 className={classes.scripTitle} onClick={() => { handleOpen("home") }}>HOME</h3>


                <div className={classes.openWrapper} style={open === "home" ? { height: "auto", opacity: "1", maxHeight: "14000px", paddingTop: "2vh", zIndex: "99" } : { maxHeight: "0", zIndex: "-1",  opacity: "0" }}>


                    <div className={classes.inputs}>
                        <label>
                            Website Title:
                            <input type="text" value={state.homeTitle} name='homeTitle' onChange={handleState} />
                        </label>
                        <label>
                            Strapline:
                            <input type="text" value={state.homeStrap} name='homeStrap' onChange={handleState} />
                        </label>
                        <label>
                            Home Page Description Title:
                            <input type="text" value={state.homeDescTitle} name='homeDescTitle' onChange={handleState} />
                        </label>


                    </div>

                    <div className={classes.photoSection}>



                        <div className={classes.newUpload}>
                            <label className={classes.btnUpload2} htmlFor="photoEdit">Logo Image</label>
                            <input
                                id="photoEdit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto(e.target.files[0]) }}
                            />

                            {photo === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.logoImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload}>
                            <label className={classes.btnUpload2} htmlFor="photo2Edit">Home Bottom Image</label>
                            <input
                                id="photo2Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto2(e.target.files[0]) }}
                            />

                            {photo2 === "" ? (
                                <img src={`https://kakushinapi.onrender.com/images/${state?.homePortfolioImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            ) : (
                                <img src={URL.createObjectURL(photo2)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo15Edit">Home Small Image</label>
                            <input
                                id="photo15Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto15(e.target.files[0]) }}
                            />

                            {photo15 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.homeIntroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo15)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo28Edit">Link Img</label>
                            <input
                                id="photo28Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto28(e.target.files[0]) }}
                            />

                            {photo28 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.ogImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo28)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.upload} >
                            <label className={classes.btnUpload2} htmlFor="photosEdit">Home Page Gallery</label>
                            <input id="photosEdit" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit} />
                            <div className={classes.keywords} >
                                <DragDropContext onDragEnd={onDragEnd} >
                                    <Droppable droppableId="panelItems">
                                        {(provided) => (
                                            <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                                {photos?.map((photo, index) => (

                                                    <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                        {(provided) => (

                                                            <div className={classes.keyword} ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} key={index}>

                                                                <AiOutlineClose onClick={() => {
                                                                    deletePhoto(photo)
                                                                }} className={classes.removeIcon} />
                                                                <img
                                                                    src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://kakushinapi.onrender.com/images/${photo}`}
                                                                    style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                    alt={`Uploaded ${index + 1}`}
                                                                />

                                                            </div>
                                                        )}



                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>



                    </div>



                    <h6>Home Main Description</h6>
                    <textarea className={classes.scrip} type="text" value={state.homeDesc} name='homeDesc' onChange={handleState} />
                    <h6>Home Read More</h6>
                    <textarea className={classes.scrip} type="text" value={state.homeReadMore} name='homeReadMore' onChange={handleState} />

                    <button className={classes.formButton2} type="submit">Update Home Page</button>

                </div>
                <h3 className={classes.scripTitle} onClick={() => { handleOpen("portfolio") }}>PORTFOLIO (MAIN PAGE)</h3>

                <div className={classes.openWrapper} style={open === "portfolio" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>



                    <div className={classes.photoSection}>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo23Edit">Portfolio Image 1</label>
                            <input
                                id="photo23Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto23(e.target.files[0]) }}
                            />

                            {photo23 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.portfolioImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo23)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>



                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo24Edit">Portfolio Image 2 </label>
                            <input
                                id="photo24Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto24(e.target.files[0]) }}
                            />

                            {photo24 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.portfolioImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo24)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo25Edit">Portfolio Image 3</label>
                            <input
                                id="photo25Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto25(e.target.files[0]) }}
                            />

                            {photo25 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.portfolioImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo25)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                    </div>

                    <button className={classes.formButton2} type="submit">Update Portfolio</button>

                </div>


                <h3 className={classes.scripTitle} onClick={() => { handleOpen("about") }}>ABOUT KAKUSHIN</h3>


                <div className={classes.openWrapper} style={open === "about" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>


                    <div className={classes.inputs}>

                        <label>
                            About Title:
                            <input type="text" value={state.aboutTitle} name='aboutTitle' onChange={handleState} />

                        </label>

                        <label>
                            About Description Title:
                            <input type="text" value={state.aboutDescTitle} name='aboutDescTitle' onChange={handleState} />

                        </label>

                    </div>


                    <div className={classes.photoSection}>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo3Edit">About Top Image</label>
                            <input
                                id="photo3Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto3(e.target.files[0]) }}
                            />

                            {photo3 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.aboutCoverImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo3)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo16Edit" > About Small Image</label>
                            <input
                                id="photo16Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto16(e.target.files[0]) }}
                            />

                            {photo16 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.aboutIntroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo16)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo4Edit">About Bottom Image</label>
                            <input
                                id="photo4Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto4(e.target.files[0]) }}
                            />

                            {photo4 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.aboutBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo4)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                    </div>

                    <h6>About Main Description</h6>
                    <textarea className={classes.scrip} type="text" value={state.aboutDesc} name='aboutDesc' onChange={handleState} />

                    <h6>About Read More</h6>
                    <textarea className={classes.scrip} type="text" value={state.aboutReadMore} name='aboutReadMore' onChange={handleState} />
                    <button className={classes.formButton2} type="submit">Update About Kakushin</button>
                </div>
                <h3 className={classes.scripTitle} onClick={() => { handleOpen("do") }}>WHAT WE DO</h3>


                <div className={classes.openWrapper} style={open === "do" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>


                    <div className={classes.inputs}>

                        <label>
                            What We Do Title:
                            <input type="text" value={state.weDoTitle} name='weDoTitle' onChange={handleState} />

                        </label>



                        <label>
                            What We Do Desc Title 1:
                            <input type="text" value={state.weDoDescTitle1} name='weDoDescTitle1' onChange={handleState} />

                        </label>

                        <label>
                            What We Do Desc Title 2:
                            <input type="text" value={state.weDoDescTitle2} name='weDoDescTitle2' onChange={handleState} />

                        </label>

                        <label>
                            What We Do Desc Title 3:
                            <input type="text" value={state.weDoDescTitle3} name='weDoDescTitle3' onChange={handleState} />

                        </label>

                        <label>
                            What We Do Desc Title 4:
                            <input type="text" value={state.weDoDescTitle4} name='weDoDescTitle4' onChange={handleState} />

                        </label>

                    </div>

                    <div className={classes.photoSection}>



                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo5Edit">What We Do - Top Image</label>
                            <input
                                id="photo5Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto5(e.target.files[0]) }}
                            />

                            {photo5 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoCoverImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo5)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo6Edit">What We Do - Bottom Image</label>
                            <input
                                id="photo6Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto6(e.target.files[0]) }}
                            />

                            {photo6 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo6)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo19Edit">What We Do Image 1</label>
                            <input
                                id="photo19Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto19(e.target.files[0]) }}
                            />

                            {photo19 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo19)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>




                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo20Edit">What We Do Image 2</label>
                            <input
                                id="photo20Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto20(e.target.files[0]) }}
                            />

                            {photo20 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo20)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>










                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo21Edit">What We Do Image 3</label>
                            <input
                                id="photo21Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto21(e.target.files[0]) }}
                            />

                            {photo21 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo21)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>




                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo22Edit">What We Do Image 4</label>
                            <input
                                id="photo22Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto22(e.target.files[0]) }}
                            />

                            {photo22 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weDoImg4}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo22)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>





                    </div>

                    <h6>What We Do Desc 1</h6>

                    <textarea className={classes.scrip} type="text" value={state.weDoDesc1} name='weDoDesc1' onChange={handleState} />

                    <h6>What We Do Desc 2</h6>


                    <textarea className={classes.scrip} type="text" value={state.weDoDesc2} name='weDoDesc2' onChange={handleState} />

                    <h6>What We Do Desc 3</h6>


                    <textarea className={classes.scrip} type="text" value={state.weDoDesc3} name='weDoDesc3' onChange={handleState} />

                    <h6>What We Do Desc 4</h6>

                    <textarea className={classes.scrip} type="text" value={state.weDoDesc4} name='weDoDesc4' onChange={handleState} />
                    <button className={classes.formButton2} type="submit">Update What We Do</button>

                </div>
                <h3 className={classes.scripTitle} onClick={() => { handleOpen("work") }}>WHERE WE WORK</h3>


                <div className={classes.openWrapper} style={open === "work" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>

                    <div className={classes.inputs}>
                        <label>
                            Section Title:
                            <input type="text" value={state.weWorkTitle} name='weWorkTitle' onChange={handleState} />

                        </label>

                    </div>

                    <div className={classes.inputs}>
                        <label>
                            Desc Title:
                            <input type="text" value={state.weWorkDescTitle} name='weWorkDescTitle' onChange={handleState} />

                        </label>

                    </div>

                    <div className={classes.photoSection}>
                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo7Edit">Where We Work - Top Image</label>
                            <input
                                id="photo7Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto7(e.target.files[0]) }}
                            />

                            {photo7 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weWorkCoverImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo7)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>



                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo8Edit">Where We Work - Bottom Image</label>
                            <input
                                id="photo8Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto8(e.target.files[0]) }}
                            />

                            {photo8 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weWorkBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo8)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo18Edit">Where We Work Small Image</label>
                            <input
                                id="photo18Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto18(e.target.files[0]) }}
                            />

                            {photo18 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.weWorkIntroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo18)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>
                    </div>



                    <h6>Where We Work Desc</h6>
                    <textarea className={classes.scrip} type="text" value={state.weWorkDesc} name='weWorkDesc' onChange={handleState} />
                    <button className={classes.formButton2} type="submit">Update Where We Work</button>

                </div>

                <h3 className={classes.scripTitle} onClick={() => { handleOpen("ethos") }}>OUR ETHOS</h3>


                <div className={classes.openWrapper} style={open === "ethos" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>

                    <div className={classes.inputs}>
                        <label>
                            Our Ethos Title:
                            <input type="text" value={state.ethosTitle} name='ethosTitle' onChange={handleState} />

                        </label>
                        <label>
                            Our Ethos Description Title:
                            <input type="text" value={state.ethosDescTitle} name='ethosDescTitle' onChange={handleState} />

                        </label>


                    </div>


                    <div className={classes.photoSection}>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo9Edit">Our Ethos - Top Image</label>
                            <input
                                id="photo9Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto9(e.target.files[0]) }}
                            />

                            {photo9 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.ethosCoverImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo9)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>



                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo10Edit">Our Ethos - Bottom Image</label>
                            <input
                                id="photo10Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto10(e.target.files[0]) }}
                            />

                            {photo10 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.ethosBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo10)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo17Edit">Our Ethos Small Image</label>
                            <input
                                id="photo17Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto17(e.target.files[0]) }}
                            />

                            {photo17 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.ethosIntroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo17)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                    </div>
                    <h6>Our Ethos Description</h6>
                    <textarea className={classes.scrip} type="text" value={state.ethosDesc} name='ethosDesc' onChange={handleState} />

                    <button className={classes.formButton2} type="submit">Update Our Ethos</button>

                </div>


                <h3 className={classes.scripTitle} onClick={() => { handleOpen("case") }}>CASE STUDIES</h3>

                <div className={classes.openWrapper} style={open === "case" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>

                    <div className={classes.inputs}>
                        <label>
                            Case Studies Title:
                            <input type="text" value={state.caseStudiesTitle} name='caseStudiesTitle' onChange={handleState} />

                        </label>

                    </div>


                    <div className={classes.photoSection} >

                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo12Edit">Case Study - Top Image</label>
                            <input
                                id="photo12Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto12(e.target.files[0]) }}
                            />

                            {photo12 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.caseStudiesCoverImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo12)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo13Edit">Case Study - Bottom Image</label>
                            <input
                                id="photo13Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto13(e.target.files[0]) }}
                            />

                            {photo13 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.caseStudiesBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo13)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>


                    </div>

                    <button className={classes.formButton2} type="submit">Update Case Studies</button>
                </div>


                <h3 className={classes.scripTitle} onClick={() => { handleOpen("contact") }}>CONTACT</h3>



                <div className={classes.openWrapper} style={open === "contact" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0" }}>
                    <div className={classes.inputs}>

                        <label>
                            Address (Main):
                            <input type="text" value={state.contactAddress} name='contactAddress' onChange={handleState} />

                        </label>









                        <label>
                            Area:
                            <input type="text" value={state.contactAddressArea} name='contactAddressArea' onChange={handleState} />

                        </label>

                        <label>
                            City:
                            <input type="text" value={state.contactAddressCity} name='contactAddressCity' onChange={handleState} />

                        </label>


                        <label>
                            Postcode:
                            <input type="text" value={state.contactAddressPostcode} name='contactAddressPostcode' onChange={handleState} />

                        </label>

                        <label>
                            Opening Days:
                            <input type="text" value={state.contactOpeningDays} name='contactOpeningDays' onChange={handleState} />

                        </label>







                        <label>
                            Opening Times:
                            <input type="text" value={state.contactOpeningTimes} name='contactOpeningTimes' onChange={handleState} />

                        </label>

                        <label>
                            Email:
                            <input type="text" value={state.footerEmail} name='footerEmail' onChange={handleState} />

                        </label>


                        <label>
                            Number:
                            <input type="text" value={state.footerTelephone} name='footerTelephone' onChange={handleState} />

                        </label>


                        <label>
                            Faceboook Link:
                            <input type="text" value={state.facebookLink} name='facebookLink' onChange={handleState} />

                        </label>


                        <label>
                            LinkedIn Link:
                            <input type="text" value={state.linkedInLink} name='linkedInLink' onChange={handleState} />

                        </label>


                        <label>
                            Instagram Link:
                            <input type="text" value={state.instaLink} name='instaLink' onChange={handleState} />

                        </label>

                    </div>
                    <div className={classes.photoSection}>
                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo14Edit">Contact Image</label>
                            <input
                                id="photo14Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto14(e.target.files[0]) }}
                            />

                            {photo14 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.contactImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo14)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>
                    </div>

                    <h6>Contact Description</h6>
                    <textarea className={classes.scrip} type="text" value={state.contactDesc} name='contactDesc' onChange={handleState} />
                    <h6>Contact Message</h6>
                    <textarea className={classes.scrip} type="text" value={state.contactMsg} name='contactMsg' onChange={handleState} />
                    <button className={classes.formButton2} type="submit">Update Contact</button>

                </div>



                <h3 className={classes.scripTitle} onClick={() => { handleOpen("gallery") }}>GALLERY</h3>

                <div className={classes.openWrapper} style={open === "gallery" ? { height: "auto", maxHeight: "14000px", paddingTop: "2vh", zIndex: "99", opacity: "1"  } : 
                { maxHeight: "0"  , zIndex: "-11",  opacity: "0" }}>
                    <div className={classes.photoSection}>
                        <div className={classes.newUpload} >
                            <label className={classes.btnUpload2} htmlFor="photo11Edit">Gallery Image - Bottom</label>
                            <input
                                id="photo11Edit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => { setPhoto11(e.target.files[0]) }}
                            />

                            {photo11 === "" ? (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.galleryBottomImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo11)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                        </div>

                        <div className={classes.upload} >
                            <label className={classes.btnUpload2} htmlFor="photosEdit2">Gallery Images</label>
                            <input id="photosEdit2" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit2} />
                            <div className={classes.keywords} >
                                <DragDropContext onDragEnd={onDragEnd2} >
                                    <Droppable droppableId="panelItems">
                                        {(provided) => (
                                            <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                                {photos2?.map((photo, index) => (

                                                    <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                        {(provided) => (

                                                            <div className={classes.keyword} ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} key={index}>

                                                                <AiOutlineClose onClick={() => {
                                                                    deletePhoto2(photo)
                                                                }} className={classes.removeIcon} />
                                                                <img
                                                                    src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://kakushinapi.onrender.com/images/${photo}`}
                                                                    style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                    alt={`Uploaded ${index + 1}`}
                                                                />

                                                            </div>
                                                        )}



                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>


                    </div>

                    <h6>Gallery Quote</h6>
                    <textarea className={classes.scrip} type="text" value={state.galleryQuote} name='galleryQuote' onChange={handleState} />
                    <button className={classes.formButton2} type="submit">Update Gallery</button>
                </div>




























            </form>



        </div>
    )
}

export default AdminWebsite