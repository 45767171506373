import React, { useState, useEffect } from 'react';
import styles from "./sting.module.css";
import { request } from '../../util/fetchAPI'

const Sting = ({DBlogo}) => {
  const PF = "https://kakushinapi.onrender.com/images/" ;
  const [logoVisible, setLogoVisible] = useState(false);
  const [stingVisible, setStingVisible] = useState(true)
  const [stingGone, setStingGone] = useState(false)
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoVisible(true);
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
        setStingVisible(false);
    }, 2200); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
        setStingGone(true);
    }, 3200); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);


  const [logo, setLogo] = useState()
    
  const [website, setWebsite] = useState([]);
  
  
  const fetchWebsite = async () => {
    const data = await request(`/website/getAll`, 'GET');
    setWebsite(data);
    console.log(data);

  };


  useEffect(() => {

    fetchWebsite();

  }, [])



  useEffect(() => {


    if (website.length > 0) {

      console.log(website[0].footerEmail)

      setLogo(website[0].logoImg)
      


    }


  }, [website])
  






  return (
    <div className={styles.container}  style={{
        opacity: stingVisible ? 1 : 0,
        transition: 'all 1.2s ease-in-out',
        visibility: stingVisible ? 'visible' : 'hidden',
        zIndex: stingGone? "-99" : "99999"
       
       
      }}>
      <div className={styles.imgCont}>
      

       
        <img
          src={PF + DBlogo}
          style={{
            opacity: logoVisible ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            visibility: logoVisible ? 'visible' : 'hidden',
          }}
          alt="Logo"
        />
   
          </div>
    </div>
  );
}

export default Sting;