import React from 'react'
import classes from "./homePortfolio.module.css"
import { Link, useNavigate } from 'react-router-dom'

const HomePortfolio = ({homePortfolioTitle, homePortfolioImg}) => {

    const PF = "https://kakushinapi.onrender.com/images/";

    const navigate = useNavigate()

    const handleClick = () => {
     
      // Perform any additional logic before navigating to the link
      navigate( "/portfolio?residential");
    };

  return (
    <div className={classes.container} onClick={handleClick}>

        <div className={classes.abso}>
            <h3>Portfolio</h3>

          
        </div>
        <img src={PF + homePortfolioImg} alt="Beautiful scene of one of the properties Kakushin has worked on"/>


    </div>
  )
}

export default HomePortfolio