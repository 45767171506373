import React, { useEffect, useState } from 'react'
import classes from "./adminEdit.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'


const AdminEdit = ({ property, closeModal, fetchFunction, preloadFunction }) => {

    const { token, user } = useSelector((state) => state.auth)



    const { id } = useParams()

    const navigate = useNavigate()

    const [newOwner, setNewOwner] = useState("")
    const [newPhotos, setNewPhotos] = useState([]);
    const [newPhotos2, setNewPhotos2] = useState([]);
    const [state, setState] = useState({

        desc1: "",
        desc2: "",
        descTitle1: "",
        descTitle2: "",
        title: "",
        createdAt
            :
            "2023-06-02T11:18:36.142Z",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",
        },


        galleryImages
            :
            [],

        mainImage
            :
            "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
        subImage1
            :
            "",
        subImage2
            :
            "",
        subImage3
            :
            "",
        subImage4
            :
            "",
        subImage5
            :
            "",
        subImage6
            :
            "",
        subImage7
            :
            "",
        subImage8
            :
            "",

        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",

    })
    const [allAgents, setAllAgents] = useState([]);
    const [photos, setPhotos] = useState(state?.galleryImages);


    const [currentOwner, setCurrentOwner] = useState(state?.currentOwner)
    const [userDetail, setUserDetail] = useState({})
    const [photo, setPhoto] = useState(property?.mainImage)
    const [photo2, setPhoto2] = useState(property?.subImage1)
    const [photo3, setPhoto3] = useState(property?.subImage2)

    const [photo5, setPhoto5] = useState(property?.subImage3)
    const [photo6, setPhoto6] = useState(property?.subImage4)
    const [photo7, setPhoto7] = useState(property?.subImage5)
    const [photo8, setPhoto8] = useState(property?.subImage6)
    const [photo9, setPhoto9] = useState(property?.subImage7)
    const [photo10, setPhoto10] = useState(property?.subImage8)
    const [photo11, setPhoto11] = useState(property?.thumbImage)






    const [selectedType, setSelectedType] = useState("")






    useEffect(() => {
        const fetchAllAgents = async () => {
            const data = await request(`/portfolio/users`, 'GET')
            setAllAgents(data)
        }
        fetchAllAgents()

    }, [])


    const fetchDetails = async () => {
        try {
            const data = await request(`/portfolio/user/${newOwner}`, 'GET');
            setUserDetail(data);

        } catch (error) {
            console.error(error);
        }
    };


    const handleOption = (e) => {

        const selectedDataId = e.target.value;
        setNewOwner(selectedDataId)
    }



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos(updatedItems);
    };



    useEffect(() => {

        if (newOwner !== "") {

            fetchDetails();


        }


    }, [newOwner]);











    useEffect(() => {
        setState({
            ...property,
        });

        setPhotos(property?.galleryImages);
        setSelectedType(property?.type)

        setPhoto(property?.mainImage)
        setPhoto2(property?.subImage1)
        setPhoto3(property?.subImage2)

        setPhoto5(property?.subImage3)
        setPhoto6(property?.subImage4)
        setPhoto7(property?.subImage5)
        setPhoto8(property?.subImage6)
        setPhoto9(property?.subImage7)
        setPhoto10(property?.subImage8)
        setPhoto11(property?.thumbImage)

        setCurrentOwner(property.currentOwner);

        console.log(property);


    }, [property]);



    useEffect(() => {

        console.log(state);

        setPhotos(property?.galleryImages)
        setSelectedType(property?.type)



    }, [state])






    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })


    }

    const handleUploadPhotoEdit = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);


    };

























    const handleListProperty = async (e) => {
        e.preventDefault();

        preloadFunction(true)

        let dateStampedPhotos = [];


        let filename = "";
        let filename2 = "";
        let filename3 = "";
        let filename5 = "";
        let filename6 = "";
        let filename7 = "";
        let filename8 = "";
        let filename9 = "";
        let filename10 = "";
        let filename20 = "";


        let initialPhotos = property?.galleryImages;

        // Current array of photos (new photos might have been added)


        // Function to determine if a photo is new (added to the array)
        function isPhotoEdited(photo, initialPhotos) {
            return !initialPhotos.includes(photo);
        }

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos = [...photos]; // Create a copy of photos2
        let editOldPhotos = [];
        let editOldPhotosIndices = [];
        let remainingOldPhotos = [];
        let remainingOldPhotosIndices = [];

        oldPhotos.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos)) {
                editOldPhotos.push(photo);
                editOldPhotosIndices.push(index);
            } else {
                remainingOldPhotos.push(photo);
                remainingOldPhotosIndices.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPhotos.length > 0) {
            const uploadPromises = editOldPhotos.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos2.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPhotos[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos = new Array(oldPhotos.length);

        // Place edited photos back into their original positions
        editOldPhotos.forEach((photo, i) => {
            combinedPhotos[editOldPhotosIndices[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos.forEach((photo, i) => {
            combinedPhotos[remainingOldPhotosIndices[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos = combinedPhotos;






        if (photo !== property.mainImage) {

            if (photo === "") {

                filename = ""



            } else {
                const formData = new FormData()




                filename = crypto.randomUUID() + photo.name
                formData.append("filename", filename)
                formData.append("image", photo)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename = state.mainImage
        }


        if (photo2 !== property.subImage1) {

            if (photo2 === "") {

                filename2 = ""



            } else {
                const formData = new FormData()




                filename2 = crypto.randomUUID() + photo2.name
                formData.append("filename", filename2)
                formData.append("image", photo2)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename2 = state.subImage1
        }


        if (photo3 !== property.subImage2) {

            if (photo3 === "") {

                filename3 = ""



            } else {
                const formData = new FormData()




                filename3 = crypto.randomUUID() + photo3.name
                formData.append("filename", filename3)
                formData.append("image", photo3)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename3 = state.subImage2
        }


        if (photo5 !== property.subImage3) {


            if (photo5 === "") {

                filename5 = ""



            } else {
                const formData = new FormData()




                filename5 = crypto.randomUUID() + photo5.name
                formData.append("filename", filename5)
                formData.append("image", photo5)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename5 = state.subImage3
        }

        if (photo6 !== property.subImage4) {

            if (photo6 === "") {

                filename6 = ""



            } else {
                const formData = new FormData()




                filename6 = crypto.randomUUID() + photo6.name
                formData.append("filename", filename6)
                formData.append("image", photo6)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename6 = state.subImage4
        }


        if (photo7 !== property.subImage5) {

            if (photo7 === "") {

                filename7 = ""



            } else {
                const formData = new FormData()




                filename7 = crypto.randomUUID() + photo7.name
                formData.append("filename", filename7)
                formData.append("image", photo7)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename7 = state.subImage5
        }


        if (photo8 !== property.subImage6) {

            if (photo8 === "") {

                filename8 = ""



            } else {
                const formData = new FormData()




                filename8 = crypto.randomUUID() + photo8.name
                formData.append("filename", filename8)
                formData.append("image", photo8)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename8 = state.subImage6
        }

        if (photo9 !== property.subImage7) {

            if (photo9 === "") {

                filename9 = ""



            } else {
                const formData = new FormData()




                filename9 = crypto.randomUUID() + photo9.name
                formData.append("filename", filename9)
                formData.append("image", photo9)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename9 = state.subImage7
        }


        if (photo10 !== property.subImage8) {

            if (photo10 === "") {

                filename10 = ""



            } else {
                const formData = new FormData()




                filename10 = crypto.randomUUID() + photo10.name
                formData.append("filename", filename10)
                formData.append("image", photo10)

                await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename10 = state.subImage8
        }


        if (photo11 !== property.thumbImage) {

            if (photo11 === "") {

                filename20 = ""



            } else {
                const formData = new FormData()




                filename20 = crypto.randomUUID() + photo11.name
                formData.append("filename", filename20)
                formData.append("image", photo11)

                await request('/upload/image', "POST", {}, formData, true)

            }

        }
        else {
            filename20 = state.thumbImage
        }













        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;



            if (userDetail.username !== undefined) {




                data = await request(`/portfolio/${property?._id}`, 'PUT', options, {
                    ...state,
                    galleryImages: finalPhotos,
                    mainImage: filename,
                    type: selectedType,
                    subImage1: filename2,
                    subImage2: filename3,
                    subImage3: filename5,
                    subImage4: filename6,
                    subImage5: filename7,
                    subImage6: filename8,
                    subImage7: filename9,
                    subImage8: filename10,
                    thumbImage: filename20,


                    currentOwner: userDetail
                })

            }

            else {

                data = await request(`/portfolio/${property?._id}`, 'PUT', options, {
                    ...state,
                    galleryImages: [...photos, ...dateStampedPhotos],
                    mainImage: filename,
                    type: selectedType,
                    subImage1: filename2,
                    subImage2: filename3,
                    subImage3: filename5,
                    subImage4: filename6,
                    subImage5: filename7,
                    subImage6: filename8,
                    subImage7: filename9,
                    subImage8: filename10,
                    thumbImage: filename20
                })

            }




            setNewPhotos([]);

            setUserDetail({})

            fetchFunction();


            alert("Your property has been successfully updated!")


            setSelectedType("")




            closeModal()
            preloadFunction()


        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }



    //PROPERTY TYPE FOR PORTFOLIO






    const handleTypeChange = (event) => {
        const { value } = event.target;
        setSelectedType(value);

    };












    const deletePhoto = (photo) => {

        setNewPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };



    useEffect(() => {

        console.log(photos)

    }, [photos])






    return (

        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
            <h2>Edit Property</h2>
            <form onSubmit={handleListProperty}>





                <div className={classes.inputs}>
                    <label>
                        Property Name:
                        <input type="text" value={state.name} name='name' onChange={handleState} />
                    </label>
                    <label>
                        Title for Description 1:
                        <input type="text" value={state.descTitle1} name='descTitle1' onChange={handleState} />

                    </label>

                    <label>
                        Title for Description 2:
                        <input type="text" value={state.descTitle2} name='descTitle2' onChange={handleState} />

                    </label>





                </div>



                <h3 className={classes.scripTitle}>Property description:</h3>
                <textarea className={classes.scrip} type="text" value={state.desc1} name='desc1' onChange={handleState} />
                <textarea className={classes.scrip} type="text" value={state.desc2} name='desc2' onChange={handleState} />



                <div className={classes.type}>
                    <label>Building Type:</label>
                    <div className={classes.radio}>
                        <label>
                            <input
                                type="radio"
                                name="buildType"
                                value="commercial"
                                checked={selectedType === 'commercial'}
                                onChange={handleTypeChange}
                            />
                            Commercial
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="buildType"
                                value="residential"
                                checked={selectedType === 'residential'}
                                onChange={handleTypeChange}
                            />
                            Residential
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="buildType"
                                value="leisure"
                                checked={selectedType === 'leisure'}
                                onChange={handleTypeChange}
                            />
                            Leisure
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="buildType"
                                value="concept"
                                checked={selectedType === 'concept'}
                                onChange={handleTypeChange}
                            />
                            Concept
                        </label>



                    </div>

                </div>


                <h3 className={classes.scripTitle}>Update Photos:</h3>
                <div className={classes.types2}>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhotoEdit">Main Image</label>

                        <input
                            id="portPhotoEdit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto(e.target.files[0]) }}
                        />

                        {photo === "" ? (
                            <p>No image present</p>


                        ) : photo !== "" && typeof photo === "string" ?





                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.mainImage}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>



                    <div className={classes.upload}>
                        <label className={classes.btnUpload2} htmlFor="portPhoto2Edit">Sub Image 1</label>
                        {photo2 !== "" && <div onClick={() => { setPhoto2("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto2Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto2(e.target.files[0]) }}
                        />

                        {photo2 === "" ? (
                            <p>No image present</p>


                        ) : photo2 !== "" && typeof photo2 === "string" ?



                            (
                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            ) : (
                                <img src={URL.createObjectURL(photo2)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>


                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto3Edit">Sub Image 2</label>
                        {photo3 !== "" && <div onClick={() => { setPhoto3("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto3Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto3(e.target.files[0]) }}
                        />

                        {photo3 === "" ? (
                            <p>No image present</p>


                        ) : photo3 !== "" && typeof photo3 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo3)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto5Edit">Sub Image 3</label>
                        {photo5 !== "" && <div onClick={() => { setPhoto5("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto5Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto5(e.target.files[0]) }}
                        />

                        {photo5 === "" ? (
                            <p>No image present</p>


                        ) : photo5 !== "" && typeof photo5 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo5)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>


                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto6Edit">Sub Image 4</label>
                        {photo6 !== "" && <div onClick={() => { setPhoto6("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto6Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto6(e.target.files[0]) }}
                        />

                        {photo6 === "" ? (
                            <p>No image present</p>


                        ) : photo6 !== "" && typeof photo6 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage4}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo6)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto7Edit">Sub Image 5</label>
                        {photo7 !== "" && <div onClick={() => { setPhoto7("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto7Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto7(e.target.files[0]) }}
                        />

                        {photo7 === "" ? (
                            <p>No image present</p>


                        ) : photo7 !== "" && typeof photo7 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage5}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo7)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>
                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto8Edit">Sub Image 6</label>
                        {photo8 !== "" && <div onClick={() => { setPhoto8("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto8Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto8(e.target.files[0]) }}
                        />

                        {photo8 === "" ? (
                            <p>No image present</p>


                        ) : photo8 !== "" && typeof photo8 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage6}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo8)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>
                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto9Edit">Sub Image 7</label>
                        {photo9 !== "" && <div onClick={() => { setPhoto9("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto9Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto9(e.target.files[0]) }}
                        />

                        {photo9 === "" ? (
                            <p>No image present</p>


                        ) : photo9 !== "" && typeof photo9 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage7}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo9)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>
                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto10Edit">Sub Image 8</label>
                        {photo10 !== "" && <div onClick={() => { setPhoto10("") }} className={classes.btnUpload}>Empty Img</div>}
                        <input
                            id="portPhoto10Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto10(e.target.files[0]) }}
                        />

                        {photo10 === "" ? (
                            <p>No image present</p>


                        ) : photo10 !== "" && typeof photo10 === "string" ?



                            (


                                <img src={`https://kakushinapi.onrender.com/images/${state?.subImage8}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo10)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            )}
                    </div>


                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhoto11Edit">Thumb Image:</label>
                        <input
                            id="portPhoto11Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto11(e.target.files[0]) }}
                        />

                        {photo11 === "" ? (
                            <p>No image present</p>


                        ) : photo11 !== "" && typeof photo11 === "string" ?



                            (

                                <img src={`https://kakushinapi.onrender.com/images/${state?.thumbImage}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                            ) : (
                                <img src={URL.createObjectURL(photo11)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />

                            )}
                    </div>














                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="portPhotosEdit">Side Images</label>
                        <input id="portPhotosEdit" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit} />


                        <div className={classes.keywords}>
                        <DragDropContext onDragEnd={onDragEnd} >
                            <Droppable droppableId="panelItems">
                                {(provided) => (
                                    <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                        {photos?.map((photo, index) => (

                                            <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                {(provided) => (

                                                    <div className={classes.keyword} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} key={index}>

                                                        <AiOutlineClose onClick={() => {
                                                            deletePhoto(photo)
                                                        }} className={classes.removeIcon} />
                                                        <img
                                                            src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://kakushinapi.onrender.com/images/${photo}`}
                                                            style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                            alt={`Uploaded ${index + 1}`}
                                                        />

                                                    </div>
                                                )}



                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        </div>

                    </div>






                </div>










                <button className={classes.formButton2} type="submit">Update Property</button>
            </form>



        </div>


    )
}

export default AdminEdit