import React from 'react'
import classes from "./aboutCover.module.css"
import { FaArrowDown } from 'react-icons/fa';

const AboutCover = ({img, title, propDetail, arrow}) => {

    const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div className={classes.container}>
    <div className={classes.abso}>
       <div className={classes.innerAbso}>
       <p>{title}</p>


       </div>
       
   </div>
   { arrow && (
        <div className={classes.scrollIcon}><FaArrowDown/></div>)}
   <img src={PF + img} alt="Elegant and beautiful design work by Kakushin Interior Design"/>

  
</div>
  )
}

export default AboutCover