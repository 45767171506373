import React from 'react'
import classes from "./contactMain.module.css"

const ContactMain = ({desc, message, address, area, city, postcode, days, hours, phone, email, img}) => {

   
    const PF = "https://kakushinapi.onrender.com/images/" ;

    return (
        <div className={classes.container}>

            <div className={classes.wrapper}>


                <div className={classes.top}>

                    <div className={classes.left}>
                        <h3>Contact</h3>
                    <p>{desc}</p>

                        <h4>Address</h4>

                        <span>{address}</span>
                        <span>{area}</span>
                        <span>{city}</span>
                        <span>{postcode}</span>


                        <h4>Opening hours</h4>

                        <span>{days} | {hours}</span>

                        <h4>Email</h4>

                        <span>{email}</span>

                        <h4>Telephone</h4>

                        <span>{phone}</span>


                    </div>


                    <div className={classes.right}>

                        <div className={classes.imgDiv}>
                            <img src={PF + img} alt="This is our beautiful, open work environment" />
                        </div>

                    </div>

                </div>
                <div className={classes.bottom}>
                    
                    <p>{message}</p>


                </div>

            </div>

        </div>
    )
}

export default ContactMain