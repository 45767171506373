import React, { useEffect, useState } from 'react'
import classes from "./Intro.module.css"
import { FaArrowDown, FaArrowUp} from "react-icons/fa";


const Intro = ({ img, title, reverse, text,  readMore, propDetail }) => {

    //PARENT TEMPLATE text={{t1: "", t2: ""}} title={{titleContent: ""}}




    const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;
    const [increaseWidth, setIncreaseWidth] = useState(false)


    useEffect(() => {

        setIncreaseWidth(false);

    }, [])






    const handleMore = () => {


        if (increaseWidth === false) {
            setIncreaseWidth(true);

        } else {
            setIncreaseWidth(false)
        }



    }

    return (
        <div className={classes.container}>

            <div className={classes.wrapper} style={reverse}>
                <div className={classes.photo}>
                    <img src={img ? PF + img?.imgTitle : PF + "oldbatch3.jpg"} alt="Lead designer at Kakushin, Pauline McHugh" />


                </div>
                <div className={classes.desc}  >
                    <h3>{title ? title.titleContent : "Home"}</h3>
                    <p>{text ? text.t1 : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi excepturi laudantium odit architecto accusamus id ullam, officiis iure voluptatibus.Nostrum nulla voluptatum explicabo optio consequatur?"}</p>
                    <p>{text ? text.t2 : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi excepturi laudantium odit architecto accusamus id ullam, officiis iure voluptatibus.Nostrum nulla voluptatum explicabo optio consequatur?"}</p>

                    {(readMore) && (
                    <div className={classes.readMore} onClick={handleMore}> {increaseWidth?  <FaArrowUp/> : <FaArrowDown/>}READ {increaseWidth?  "LESS" : "MORE"} </div>
                    )}

                </div>


            </div>
{(readMore) && (

            <div className={classes.extraText} style={increaseWidth ? { height: "auto", maxHeight: "500px", paddingTop: "2vh" } :  { maxHeight: "0"} } >
                <p>{text ? text.t3 : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi excepturi laudantium odit architecto accusamus id ullam, officiis iure voluptatibus.Nostrum nulla voluptatum explicabo optio consequatur?"}</p>
                <p>{text ? text.t4 : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi excepturi laudantium odit architecto accusamus id ullam, officiis iure voluptatibus.Nostrum nulla voluptatum explicabo optio consequatur?"} </p>
               
            </div>)}

        </div>
    )
}

export default React.memo(Intro)