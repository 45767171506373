import React from 'react'
import classes from "./skinnyPhotos.module.css"

const SkinnyPhotos = ({propDetail, img1, img2}) => {

    const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;

  return (
    <div className={classes.container}>
        <div className={classes.wrapper}>
            <div className={classes.box1}>
                <img src={PF + img1} alt="little" />

            </div>
            <div className={classes.box2}>
            <img src={PF + img2} alt="little" />

            </div>
        </div>

    </div>
  )
}

export default React.memo(SkinnyPhotos)