import React, { useState, useEffect } from 'react';
import './imageCarousel.css'; // Create a CSS file for styling
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ImageCarousel = ({ images }) => {
    
  const PF = "https://kakushinapi.onrender.com/images/";




  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentImageIndex]);

  return (
    <div className="image-carousel">
      <img
        className="carousel-image"
        src={PF + images[currentImageIndex]}
        alt={`Image ${currentImageIndex + 1}`}
      />
      <button className="arrow carouselLeft" onClick={prevImage}>
        <FaArrowLeft/>
      </button>
      <button className="arrow carouselRight" onClick={nextImage}>
        <FaArrowRight/>
      </button>
    </div>
  );
};

export default ImageCarousel;