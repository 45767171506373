import React, {useEffect, useRef, useState} from 'react'
import classes from "./blogMain.module.css";




const BlogMain = (newEvents) => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  
  /*useEffect(() => {
    const fetchAllEvents = async () => {
      const data = await request(`/event/getAll`, 'GET');
      setAllEvents(data);
    };
    fetchAllEvents();
  }, []); */






  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("visitkinvara@gmail.com");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [remainingEvents, setRemainingEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);



  const descRef = useRef([]);


  useEffect(() => {


    setAllEvents(newEvents.newEvents)

  }, [newEvents])
  


  const formRef = useRef([]);


  const filterEvents = (events) => {

   
    
    let remainingEvents = [];





  
  
      



    
      events.forEach(event => {
        
       
 
          remainingEvents.push(event);
        
      });
    
  
    setRemainingEvents(remainingEvents)
  
  
  }


  useEffect(() => {


    console.log(allEvents)

   if(allEvents.length > 0) {
    filterEvents(allEvents);


   }
     
    
  
     
   }, [allEvents]);


   useEffect(() => {
    console.log(filteredEvents)
     
   }, [filteredEvents])


    
  useEffect(() => {

   if(sortedEvents.length > 0){

    console.log(sortedEvents)
    const appearOptions = {
      threshold: 0.11,
    };
  
     const appearOnScroll = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(classes.appear);
            observer.unobserve(entry.target);
          }
        });
      }, appearOptions);
    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });
    formRef.current.forEach((form) => {
      appearOnScroll.observe(form);
    });
  

    return () => {
      appearOnScroll.disconnect();
    };

  }
  else {
    return;
  }


  



   


  }, [sortedEvents.length]);


  useEffect(() => {
   


    function sortEventsByDatetime(eventsArray) {
      const sortedEvents = eventsArray.slice().sort((eventA, eventB) => {
        const datetimeA = new Date(eventA.datetime);
        const datetimeB = new Date(eventB.datetime);
        
        return datetimeA - datetimeB;
      });
    
      return sortedEvents;
    }

    let sortedList  = sortEventsByDatetime(remainingEvents);

    setSortedEvents(sortedList)

    

   


  }, [remainingEvents]);



  function splitTextIntoParagraphs(text) {
    const words = text.split(' ');
    const paragraphs = [];
    let currentParagraph = '';
  
  
    words.forEach(word => {
      if ((currentParagraph + word).length <= 350) {
        currentParagraph += (currentParagraph === '' ? '' : ' ') + word;
       
      } else {
        paragraphs.push(currentParagraph);

       
        currentParagraph = word;
       
      }
    });
  
    if (currentParagraph !== '') {
      paragraphs.push(currentParagraph);
    }


    let newArray = paragraphs.slice(0, 2)

    if(newArray[newArray.length - 1] !== undefined) {
   newArray[newArray.length - 1] = newArray[newArray.length -1].concat("...")

    }



 
  
    return newArray.map((paragraph, index) => (
      <p key={index} className={classes.eventDesc}>
        {paragraph}
      </p>
    ));
  }









    function formatEventDatetime(datetimeString) {
      const eventDate = new Date(datetimeString);
    
      const options = {
        timeZone: 'Europe/Dublin',
        weekday: 'long',        // Display the full weekday name
        day: 'numeric',         // Display the day of the month
        month: 'long',         // Display the full month name
        year: 'numeric',        // Display the year
        hour: 'numeric',        // Display the hour in 12-hour format
        minute: 'numeric',      // Display the minutes
        hour12: true,           // Use 12-hour format
      };
    
      const formattedDatetime = eventDate.toLocaleString('en-US', options);
      return formattedDatetime;
    }




    const PF2 = "https://kakushinapi.onrender.com/images/";


  return (
    <div className={classes.container}>

<div className={classes.middleDiv}>
<p className={classes.specialP}>KAKUSHIN BLOG</p>

  <p className={classes.topDesc}>Take a look at our plans for the next few months, featuring new builds, design plans and links to our collaborators</p>
                     

  
</div>
<div className={classes.eventsDiv}>
<p className={classes.specialP}>UPCOMING WORKS @ KAKUSHIN</p>


{remainingEvents.length !== 0 ? (
  sortedEvents.map((event, index) => (
    <div key={event._id} className={classes.row} ref={(el) => (descRef.current[index] = el)}>
      <div className={classes.imgCont}>
        <img src={`${PF2}${event.img}`} alt="opening" />
      </div>
      <div className={classes.textSection}>
        <p className={classes.specialP}>{event.title}</p>
     
        
       
          
        {event.article ? (
splitTextIntoParagraphs(event.article)
) : (
  // Handle the case when event.desc is undefined or null
   
  <p className={classes.eventDesc}>
  'Description not available'
  </p>
)}

       
      </div>
    </div>
  ))
) : (
  <h5 className={classes.message}>No blog entries currently available, please check back later...</h5>
)}




 

 




</div>

    
   {/*
  <div  className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (formRef.current[0] = el)}  onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all music enquires, use the contact form below. </p>
      
      <form onSubmit={handleSubmit}>
      
        <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />


     
       
        <button className={classes.getBtn}>SEND</button>
      </form>
      
    </div>



</div> */}

  </div>
  )
}

export default BlogMain;