import React, { useState, useEffect } from 'react'
import classes from "./footer.module.css"
import { request } from '../../util/fetchAPI'


import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = ({DBemail, DBnumber}) => {



  const [website, setWebsite] = useState([])



useEffect(() => {
  const fetchWebsite = async () => {
    const data = await request(`/website/getAll`, 'GET');
    setWebsite(data);
    console.log(data);

  };
  fetchWebsite();


}, [])
 



  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");





  useEffect(() => {




      setEmail(DBemail)
      setNumber(DBnumber)


  


  }, [])












  return (

    <>

      {(email !== "" && number !== "" && website.length > 0) ?

        (
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <span className={classes.item}>E:</span>
              <span className={classes.item}>
                {email}
              </span>
            </div>
            <div className={classes.wrapper}>
              <span className={classes.item}>T: </span>
              <span className={classes.item}>{number} </span>
            </div>
            <div className={classes.wrapper}>
              <span className={classes.item}><a  target="_blank" rel="noreferrer" href={website[0].facebookLink? website[0].facebookLink : "" }><FaFacebook /></a></span>
              <span className={classes.item}><a  target="_blank" rel="noreferrer" href={website[0].instaLink? website[0].instaLink : ""}><FaInstagram /></a></span>
              <span className={classes.item}><a  target="_blank" rel="noreferrer" href={website[0].linkedInLink? website[0].linkedInLink : "" }><FaLinkedin /></a></span>

            </div>
          </div>) :


        (<div className={classes.container}>

        </div>)



      }

    </>
  )
}

export default Footer