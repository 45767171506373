import React from 'react'
import classes from "./galleryMain.module.css"
import GalleryComponent from '../GalleryComponent/GalleryComponent'

const GalleryMain = ({photos, strap}) => {


  const dummyPhotos = [

    "About1.jpg" ,
 "About2.jpg" ,
   "About3.jpg" ,
    "About4.jpg" ,
     "background1.webp" ,
    "Case1.jpg" ,
   "case2.jpg" ,
   "Connollys1.jpg" ,
    "Connollys6.jpg" ,
  "Connollys7.jpg" ,
   "fullview.webp" ,
   "Old17.jpg",
   "Old25.jpg",
   "Old40.jpg",
   "Old58.jpg",
   "Old78.jpg",
   "Old99.jpg",
   "oldbatch1.jpg",
   "oldbatch2.jpg",
   "oldbatch3.jpg",
   "oldbatch4.jpg",
   "oldbatch5.jpg",
   "oldbatch6.jpg",
   "oldbatch7.jpg",
   "plus1.jpg",
   "stairs2.webp",
   "WhatWe0.jpg",
   "WhatWe1.jpg",
   "WhatWe2.jpg",
   "WhatWe3.jpg",
   "WhatWe4.jpg",
   "WhatWeCover.jpg"




  ]


  return (
    <div className={classes.container}>

        <div className={classes.quoteWrapper}>
        <p>"{strap}"</p>
        </div>


        <GalleryComponent propDetail={true} photos={photos}/>

   


    </div>
  )
}

export default GalleryMain