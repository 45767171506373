import React from 'react'
import classes from "./press.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'
import Intro from '../../components/Intro/Intro'
import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'

const Press = () => {
  return (
    <div>
      <Navbar scrollEvent={true} />
      <AboutCover  img={"oldbatch3.jpg"} title={"The fascinating work we are a part of"}  />
      {/*Press component */}
      <Cover  img={"oldbatch1.jpg"} />
      <Footer />
    </div>
  )
}

export default Press