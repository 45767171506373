import React from 'react'
import classes from "./home.module.css"
import Navbar from '../../components/Navbar/Navbar'
import HomeCover from '../../components/HomeCover/HomeCover'
import Intro from '../../components/Intro/Intro'
import HomePortfolio from '../../components/HomePortfolio/HomePortfolio'
import Footer from '../../components/Footer/Footer'
import Sting from '../../components/Sting/Sting'



const Home = ({homeTitle, descTitle, introImg, topImgs, bottomImg, desc, readMore, DBlogo,  DBemail, DBnumber}) => {




  return (
    <div>
      <Sting  DBlogo={DBlogo}/>
      <Navbar DBlogo={DBlogo} scrollEvent={true} />

      <HomeCover homeTitle={homeTitle}imgs={topImgs}  arrow={true} />

      <Intro
        readMore={true} img={{ imgTitle: introImg }}
        title={{ titleContent: descTitle}}
        text={{
          t1: desc,
          t3: readMore
        }} 
        propDetail={true}/>
      <HomePortfolio  homePortfolioImg={bottomImg} />
      <Footer  DBemail={DBemail} DBnumber={DBnumber} />
    </div>
  )
}

export default Home