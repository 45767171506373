import React from 'react'
import classes from "./whatWeDo.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'

import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'
import WhatWeDoMain from '../../components/WhatWeDoMain/WhatWeDoMain'

const WhatWeDo = ({title1, title2, title3, title4, desc1, desc2, desc3, desc4, img1, img2, img3, img4, bottomImg, topImg, mainTitle,  DBlogo,  DBemail, DBnumber}) => {

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
   
  };


  preloadImage("https://kakushinapi.onrender.com/images/" +img1);
  preloadImage("https://kakushinapi.onrender.com/images/" +img2);
  preloadImage("https://kakushinapi.onrender.com/images/" +img3);
  preloadImage("https://kakushinapi.onrender.com/images/" +img4);
  preloadImage("https://kakushinapi.onrender.com/images/" +bottomImg);
  preloadImage("https://kakushinapi.onrender.com/images/" +topImg)



  return (
    <div>
      <Navbar scrollEvent={true}  DBlogo={DBlogo}/>
      <AboutCover img={topImg} title={mainTitle} propDetail={true}/>

      <WhatWeDoMain title1={title1} title2={title2} title3={title3} title4={title4} desc1={desc1} desc2={desc2} desc3={desc3} desc4={desc4} img1={img1} img2={img2} img3={img3} img4={img4}/>
      <Cover  img={bottomImg} propDetail={true} />
      <Footer  DBemail={DBemail} DBnumber={DBnumber} />


    </div>
  )
}

export default WhatWeDo