import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { request } from '../../util/fetchAPI';
import classes from './portfolio.module.css';
import Navbar from '../../components/Navbar/Navbar';
import Cover from '../../components/Cover/Cover';
import Properties from '../../components/Properties/Properties';
import Footer from '../../components/Footer/Footer';

const Portfolio = ({ img1, img2, img3, properties, DBlogo, DBemail, DBnumber }) => {
  const location = useLocation();

  // State Declarations
  const [allProperties, setAllProperties] = useState([]);
  const [commercials, setCommercials] = useState([]);
  const [residentials, setResidentials] = useState([]);
  const [leisure, setLeisure] = useState([]);


  useEffect(() => {
    // Lazy load images
    [img1, img2, img3].forEach(preloadImage);
  }, [img1, img2, img3]);

  function preloadImage(url) {
    const img = new Image();
    img.src = url;
  }

  useEffect(() => {
    if (properties.length > 0) {
      setAllProperties(properties);
    }
  }, [properties]);

  useEffect(() => {
    if (allProperties.length > 0) {
      handleProperties();
    }
  }, [allProperties]);

  function handleProperties() {
    const commercialsArray = [];
    const leisureArray = [];
    const residentialsArray = [];

    allProperties.forEach(object => {
      switch (object.type) {
        case 'commercial':
          commercialsArray.push(object);
          break;
        case 'leisure':
          leisureArray.push(object);
          break;
        case 'residential':
          residentialsArray.push(object);
          break;
        default:
          break;
      }
    });

    setCommercials(commercialsArray);
    setResidentials(residentialsArray);
    setLeisure(leisureArray);
  }

  useEffect(() => {
    if (location.hash && allProperties.length > 0 ) {
      const timeoutId = setTimeout(() => {
        const targetElement = document.querySelector(location.hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash, allProperties]);




 

  return (
    <div  >
      <Navbar DBlogo={DBlogo} scrollEvent={true}  />
      <Cover title={'Residential'} arrow={true} id="residential" img={img1} propDetail={true}  />
        <Properties properties={residentials} />
        <Cover title={'Commercial'} arrow={true} id="commercial" img={img2} propDetail={true}  />
       <Properties properties={commercials} />
       <Cover title={'Leisure'} arrow={true} id="leisure" img={img3} propDetail={true}  />
       <Properties properties={leisure}/>
        <Footer DBemail={DBemail} DBnumber={DBnumber} />
    </div>
  );
};

export default Portfolio;