import React from 'react'
import classes from "./ourEthos.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'
import Intro from '../../components/Intro/Intro'
import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'

const OurEthos = ({introImg, topImg, bottomImg, title, desc, descTitle,  DBlogo,  DBemail,  DBnumber}) => {
  return (
    <div>
      <Navbar scrollEvent={true}  DBlogo={DBlogo}/>
      <AboutCover
        img={topImg}
        title={title} 
        propDetail={true}/>
      <Intro
        img={{ imgTitle: introImg }}
        propDetail={true}
        readMore={false}
        text={{
          t1: desc,
         
        }}
        title={{ titleContent: descTitle }} />
      <Cover img={bottomImg} propDetail={true} />
      <Footer  DBemail={DBemail} DBnumber={DBnumber} />




    </div>
  )
}

export default OurEthos