import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import classes from "./admin.module.css"
import { request } from '../../util/fetchAPI';
import { register, logout } from '../../redux/authSlice'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal';
import AdminEdit from '../../components/EditComponents/AdminEdit/AdminEdit';
import AdminPress from '../../components/EditComponents/AdminPress/AdminPress';
import AdminBlog from '../../components/EditComponents/AdminBlog/AdminBlog';
import AdminWebsite from '../../components/EditComponents/AdminWebsite/AdminWebsite';
import ClipLoader from "react-spinners/ClipLoader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';






const PF = process.env.REACT_APP_PUBLIC_FOLDER;


const preloadImage = (url) => {
    const img = new Image();
    img.src = url;

    return img;


};


const background = preloadImage(PF + "oldbatch5.jpg");


const Admin = () => {



    //getAll states

    const [allProperties, setAllProperties] = useState([]);
    const [allBlogs, setAllBlogs] = useState([]);
    const [allPress, setAllPress] = useState([]);



    //TAB STATES
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };






    const { user, token } = useSelector((state) => state.auth)

    //const userImage = preloadImage(`https://katteochapi.onrender.com/images/${user?.profileImg}`);


    //ALL SELF-TITLED STATES

    const [state, setState] = useState({});
    const [state2, setState2] = useState({});
    const [state3, setState3] = useState(user);
    const [state4, setState4] = useState({
        title: "",
        author: "",
        strap: "",
        article: "",
        img: ""
    });


    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [showForm3, setShowForm3] = useState(false);
    const [showForm4, setShowForm4] = useState(false);
    const [showForm5, setShowForm5] = useState(false);
    const [showForm6, setShowForm6] = useState(false);
    const [showForm7, setShowForm7] = useState(false);




    const [selectedEvent, setSelectedEvent] = useState("");

    const [selectedPress, setSelectedPress] = useState({
        pressTitle: "",
        date: "",
        desc: "",
        pressImg: "",
        externalLink: "",
        strap: "",
        article: "",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",

        },
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",
    });
    const [selectedBlog, setSelectedBlog] = useState({
        title: "",
        author: "",

        img: "",
        strap: "",
        article: "",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",

        },
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",
    });


    const [newPassword, setNewPassword] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(true);





    const [currentPassword, setCurrentPassword] = useState("")


    //WITH PROFILE UPDATE
    const [photo4, setPhoto4] = useState("")

    //WITH LIST PROPERTY, MAIN IMAGE AND SUB IMAGES
    const [photo, setPhoto] = useState("") // Main
    const [photo2, setPhoto2] = useState("")
    const [photo3, setPhoto3] = useState("")
    const [photo5, setPhoto5] = useState("")
    const [photo6, setPhoto6] = useState("")
    const [photo7, setPhoto7] = useState("")
    const [photo8, setPhoto8] = useState("")
    const [photo9, setPhoto9] = useState("")
    const [photo10, setPhoto10] = useState("")
    const [photo11, setPhoto11] = useState("")
    const [photo12, setPhoto12] = useState("")
    const [photo13, setPhoto13] = useState("")

    //GALLERY IMAGES


    const [photos, setPhotos] = useState([]);




    const [isLoading, setIsLoading] = useState(false);






    const handleLoad = () => {

        console.log("it's being handled")
        setTimeout(() => {

            setIsLoading(true)

        }, [2000]);
    }











    useEffect(() => {
        let inactivityTimeout;

        const handleInactivity = () => {
            setIsActive(false);

        };

        const resetInactivityTimeout = () => {
            if (inactivityTimeout) {
                clearTimeout(inactivityTimeout);
            }

            inactivityTimeout = setTimeout(handleInactivity, 4800000);
        };

        const handleUserActivity = () => {
            resetInactivityTimeout();
            setIsActive(true);
        };


        resetInactivityTimeout();


        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);


        return () => {
            clearTimeout(inactivityTimeout);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [allProperties]);

    useEffect(() => {

        if (isActive === false) {
            dispatch(logout());
        }

    }, [isActive])


    useEffect(() => {
        Modal.setAppElement('#root');

    }, []);






    const handleCloseForm = () => {
        setShowForm(false)
        setShowForm2(false)
        setShowForm6(false)
        setShowForm7(false)


        setPhoto(null)
        setSelectedEvent("")
        setState({})
        setState2({})
        setState4({})
    }


    const handleLogout = () => {
        dispatch(logout());
        navigate("/admin-login");
    }

    const deletePhoto = (photo) => {


        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };




    const handleNewPassword = (e) => {
        setNewPassword(e.target.value)
    }


    //ALL STATE HANDLERS



    useEffect(() => {

        console.log("STate 4:" + Object.keys(state4))

    }, [state4])



    const handleState = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState({ ...state, [name]: newValue })
    }

    const handleState2 = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState2({ ...state2, [name]: newValue })
    }

    const handleState4 = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState4({ ...state4, [name]: newValue })
    }


    const handleState3 = (e) => {
        setState3(prev => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }


    const handleCurrentPassword = (e) => {


        setCurrentPassword(e.target.value)

    }


    useEffect(() => {


        if (allProperties.length > 0) {
            handleLoad();
            console.log("All Properties:" + allProperties[0].artist)
        }



    }, [allProperties])










    //FETCHING FUNCTIONS



    const fetchAllPress = async () => {
        const data = await request(`/press/getAll`, 'GET');
        setAllPress(data);

    };


    const fetchAllBlogs = async () => {
        const data = await request(`/blog/getAll`, 'GET');
        setAllBlogs(data);

    };


    const fetchAllProperties = async () => {
        const data = await request(`/portfolio/getAll`, 'GET');
        setAllProperties(data);

    };











    //DELETE FUNCTIONS


    const deleteProperty = async () => {
        try {
            await request(`/portfolio/${selectedEvent._id}`, 'DELETE', { Authorization: `Bearer ${token}` });


            fetchAllProperties();
            setSelectedEvent("")
            alert("Your property has been successfully deleted!")

        } catch (error) {

            console.log(error);

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };


    const deleteBlog = async () => {
        try {
            await request(`/blog/${selectedBlog._id}`, 'DELETE', { Authorization: `Bearer ${token}` });



            fetchAllBlogs();

            alert("Your blog has been successfully deleted!")

        } catch (error) {

            alert(error);
        }
    };


    const deletePress = async () => {
        try {
            await request(`/press/${selectedPress._id}`, 'DELETE', { Authorization: `Bearer ${token}` });



            fetchAllPress();



            alert("Your press item has been successfully deleted!")


        } catch (error) {

            alert("Deletion failed, please try again or log out and log back in againto see if it is your AUTH token which is the problem.");
        }
    };





    const confirmDelete = () => {

        deleteProperty();


        setShowForm(false);


    };



    const confirmDelete2 = () => {

        deletePress();


        setShowForm4(false);


    };


    const confirmDelete3 = () => {

        deleteBlog();


        setShowForm5(false);


    };



    // FORM OPENERS

    const handleOpenForm3 = () => {
        setShowForm3(true);


    }


    //CREATE PORTFOLIO, PRESS AND BLOG ITEMS


    const handleListProperty = async (e) => {
        e.preventDefault();
        setPreloader(true)

        let dateStampedPhotos = [];

        let filename1 = "";
        let filename2 = "";
        let filename3 = "";

        let filename5 = "";
        let filename6 = "";
        let filename7 = "";
        let filename8 = "";
        let filename9 = "";
        let filename10 = "";
        let filename20 = "";


        if (photos.length > 0) {
            const uploadPromises = photos.map(async (photo, index) => {
                if (photo) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + photo.name;

                    dateStampedPhotos.push(filename);
                    formData.append("filename", filename);
                    formData.append("image", photo);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }
        }



        if (photo && photo !== "") {


            const formData = new FormData();

            filename1 = crypto.randomUUID() + photo.name;
            formData.append("filename", filename1);
            formData.append("image", photo);

            await request('/upload/image', "POST", {}, formData, true);
        }


        if (photo2 && photo2 !== "") {
            const formData2 = new FormData();

            filename2 = crypto.randomUUID() + photo2.name;
            formData2.append("filename", filename2); // Modified field name
            formData2.append("image", photo2); // Modified field name

            await request('/upload/image', "POST", {}, formData2, true);
        }







        if (photo3 && photo3 !== "") {
            const formData3 = new FormData();

            filename3 = crypto.randomUUID() + photo3.name;
            formData3.append("filename", filename3); // Modified field name
            formData3.append("image", photo3); // Modified field name

            await request('/upload/image', "POST", {}, formData3, true);
        }

        if (photo5 && photo5 !== "") {
            const formData5 = new FormData();

            filename5 = crypto.randomUUID() + photo5.name;
            formData5.append("filename", filename5); // Modified field name
            formData5.append("image", photo5); // Modified field name

            await request('/upload/image', "POST", {}, formData5, true);
        }

        if (photo6 && photo6 !== "") {
            const formData6 = new FormData();

            filename6 = crypto.randomUUID() + photo6.name;
            formData6.append("filename", filename6); // Modified field name
            formData6.append("image", photo6); // Modified field name

            await request('/upload/image', "POST", {}, formData6, true);
        }

        if (photo7 && photo7 !== "") {
            const formData7 = new FormData();

            filename7 = crypto.randomUUID() + photo7.name;
            formData7.append("filename", filename7); // Modified field name
            formData7.append("image", photo7); // Modified field name

            await request('/upload/image', "POST", {}, formData7, true);
        }
        if (photo8 && photo8 !== "") {
            const formData8 = new FormData();

            filename8 = crypto.randomUUID() + photo8.name;
            formData8.append("filename", filename8); // Modified field name
            formData8.append("image", photo8); // Modified field name

            await request('/upload/image', "POST", {}, formData8, true);
        }
        if (photo9 && photo9 !== "") {
            const formData9 = new FormData();

            filename9 = crypto.randomUUID() + photo9.name;
            formData9.append("filename", filename9); // Modified field name
            formData9.append("image", photo9); // Modified field name

            await request('/upload/image', "POST", {}, formData9, true);
        }
        if (photo10 && photo10 !== "") {
            const formData10 = new FormData();

            filename10 = crypto.randomUUID() + photo10.name;
            formData10.append("filename", filename10); // Modified field name
            formData10.append("image", photo10); // Modified field name

            await request('/upload/image', "POST", {}, formData10, true);
        }


        if (photo13 && photo13 !== "") {
            const formData13 = new FormData();

            filename20 = crypto.randomUUID() + photo13.name;
            formData13.append("filename", filename20); // Modified field name
            formData13.append("image", photo13); // Modified field name

            await request('/upload/image', "POST", {}, formData13, true);
        }



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state,
                galleryImages: dateStampedPhotos,
                type: selectedType,
                mainImage: filename1,
                subImage1: filename2,
                subImage2: filename3,
                subImage3: filename5,
                subImage4: filename6,
                subImage5: filename7,
                subImage6: filename8,
                subImage7: filename9,
                subImage8: filename10,
                thumbImage: filename20

            };

            await request('/portfolio', 'POST', options, requestData);

            fetchAllProperties();

            setPhotos([]);

            setPhoto("");
            setPhoto2("");
            setPhoto3("");
            setPhoto5("");
            setPhoto6("");
            setPhoto7("");
            setPhoto8("");
            setPhoto9("");
            setPhoto10("");

            setState({
                ...state,
                descTitle1: "",
                descTitle2: "",
                desc1: "",
                desc2: "",
                name: ""

            })





            alert("Your property was sucessfully created!");
            setPreloader(false)
        } catch (error) {
            console.error(error);
            alert("Your authentication token is expired. Please login again via the sign in page.");
            setPreloader(false)
        }
    };






    const handleListProperty2 = async (e) => {
        e.preventDefault();



        let filename1 = "";






        if (photo11) {


            const formData = new FormData();

            filename1 = crypto.randomUUID() + photo11.name;
            formData.append("filename", filename1);
            formData.append("image", photo11);

            await request('/upload/image', "POST", {}, formData, true);
        }





        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state2,

                pressImg: filename1,


            };

            await request('/press', 'POST', options, requestData);

            fetchAllPress();

            setState2({
                ...state2,
                date: "",
                desc: "",
                pressTitle: "",
                externalLink: ""

            })

            setPhoto11("")



            alert("Your press item was sucessfully created!");
        } catch (error) {
            console.error(error);
            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };

    const handleListProperty3 = async (e) => {
        e.preventDefault();



        let filename1 = "";






        if (photo12) {
            const formData = new FormData();

            filename1 = crypto.randomUUID() + photo12.name;
            formData.append("filename", filename1);
            formData.append("image", photo12);

            await request('/upload/image', "POST", {}, formData, true);
        }





        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state4,


                img: filename1,


            };

            await request('/blog', 'POST', options, requestData);

            fetchAllBlogs();

            setState4({
                ...state4,
                article: "",
                author: "",
                strap: "",
                title: ""

            })
            setPhoto12("")


            alert("Your press item was sucessfully created!");
        } catch (error) {
            console.error(error);
            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };




    useEffect(() => {



        fetchAllProperties();
        fetchAllBlogs();
        fetchAllPress();

    }, []);



    //SELECTOR FUNCTIONS



    useEffect(() => {

        console.log("This is the selected event:" + selectedEvent)

    }, [selectedEvent])


    const handleSelectedEvent = async (id) => {

        setSelectedEvent("")
        const data = await request(`/portfolio/find/${id}`, "GET");
        setSelectedEvent(data);


    }


    const handleSelectedPress = async (id) => {
        const data = await request(`/press/find/${id}`, "GET");
        setSelectedPress(data);


    }


    const handleSelectedBlog = async (id) => {
        const data = await request(`/blog/find/${id}`, "GET");
        setSelectedBlog(data);


    }


    //UPLOAD PHOTO FUNCTIONS


    const handleUploadPhoto = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);
    };


    //PROPERTY TYPE FOR PORTFOLIO



    const [selectedType, setSelectedType] = useState("")


    const handleTypeChange = (event) => {
        const { value } = event.target;
        setSelectedType(value);

    };








    const handleEditPreload = (bool) => {

        setPreloader(bool)
    }








    const handleSubmit = async (e) => {

        e.preventDefault()




        try {

            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }


            let filename = null
            if (photo4 !== undefined) {
                const formData = new FormData()
                filename = crypto.randomUUID() + photo4.name
                formData.append("filename", filename)
                formData.append('image', photo4)

                await request('/upload/image', "POST", {}, formData, true)



                const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, profileImg: filename, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)




            } else {


                const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)



            }









        } catch (error) {
            console.error(error)
            alert("Please enter your current password before making changes to your profile. Or signin again to refresh your authentication token");
        }


    }


    const editMenuRef = useRef(null);


    useEffect(() => {
        if (editMenuRef.current && selectedEvent !== "" && showForm2 === true) {
            editMenuRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [selectedEvent])



    const [preloader, setPreloader] = useState(false)




    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "#fff",


    };



    useEffect(() => {


        console.log(photos)


    }, [photos])


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos(updatedItems);
    };










    return (
        <div className={classes.container} >
            <div className={classes.abso} style={{ backgroundImage: `url(${background.src})`, objectFit: "cover" }}>


            </div>
            {user ? (

                <div className={classes.wrapper}>

                    <div className={classes.topBar}>
                        <div className={classes.section}>
                            <img alt="logo" src={PF + "knobg.png"} />


                        </div>
                        <div className={classes.section2}>
                            <p className={classes.logout} onClick={handleLogout}>Log Out</p>
                            <p className={classes.logout} onClick={handleOpenForm3}>
                                {user.username}
                            </p>



                        </div>

                    </div>




                    {preloader ? (
                        <div style={{ width: "auto" }}>
                            <ClipLoader
                                color={color}
                                loading={true}
                                cssOverride={override}
                                size={300}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) : (<>




                        <AdminWebsite />












                        <h2>Portfolio</h2>

                        {isLoading === false && <div className={classes.preloader}>
                            <p className={classes.loader}>Loading...</p>
                        </div>}

                        <div className={classes.properties} style={isLoading === true ? { display: "flex" } : { display: "none" }} >


                            {allProperties.map((property) => (
                                <div key={property._id} className={classes.property} >
                                    <div className={classes.imgContainer}>
                                        <img src={`https://kakushinapi.onrender.com/images/${property?.thumbImage}`} onLoad={handleLoad} alt="" />
                                    </div>

                                    <div className={classes.details}>




                                        <h3 className={classes.propName}>{property?.name}</h3>

                                        <div className={classes.selectorDiv}>

                                            <button className={classes.btnEdit} onClick={() => {
                                                setShowForm2(true);
                                                handleSelectedEvent(property._id)
                                            }}>Edit</button>

                                            <button className={classes.btnDelete} onClick={() => {
                                                setShowForm(true);
                                                handleSelectedEvent(property._id)
                                            }}>Delete</button>
                                        </div>



                                    </div>


                                </div>
                            ))}




                        </div>

                        {selectedEvent !== "" &&
                            <div className={classes.listPropertyForm} ref={editMenuRef} >
                                <AdminEdit property={selectedEvent} closeModal={handleCloseForm} fetchFunction={fetchAllProperties} preloadFunction={handleEditPreload} />
                            </div>

                        }

                        {selectedEvent !== "" && (<button onClick={() => {
                            setSelectedEvent("")
                        }} className={classes.formButton2}>Clear Property Edit</button>)}




                        <h2>Blog Entries</h2>


                        {isLoading === false && <div className={classes.preloader}>
                            <p className={classes.loader}>Loading...</p>
                        </div>}


                        <div className={classes.properties} style={isLoading === true ? { display: "flex" } : { display: "none" }} >

                            {allBlogs.map((blog) => (

                                <div key={blog._id} className={classes.property} >
                                    <div className={classes.imgContainer}>
                                        <img src={`https://kakushinapi.onrender.com/images/${blog?.img}`} onLoad={handleLoad} alt="" />
                                    </div>
                                    <div className={classes.details}>




                                        <h3 className={classes.propName}>{blog?.title}</h3>

                                        <div className={classes.selectorDiv}>

                                            <button className={classes.btnEdit} onClick={() => {
                                                setShowForm7(true);
                                                handleSelectedBlog(blog._id)
                                            }}>Edit</button>

                                            <button className={classes.btnDelete} onClick={() => {
                                                setShowForm5(true);
                                                handleSelectedBlog(blog._id)
                                            }}>Delete</button>
                                        </div>



                                    </div>


                                </div>

                            ))}

                        </div>


















                        <h2>CREATE NEW ENTRIES</h2>


                        <div className={classes.tabs}>

                            <div
                                className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                                onClick={() => handleTabClick('tab1')}

                            >
                                Create Property
                            </div>


                            <div
                                className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                                onClick={() => handleTabClick('tab3')}

                            >
                                Create Blog Entry
                            </div>

                            {/* Add more tabs as needed */}
                        </div>

                        <div className={classes.tabWrapper}>
                            {activeTab === 'tab1' && <div className={classes.listPropertyForm} >
                                <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                    <h2>Create Property</h2>
                                    <form onSubmit={handleListProperty}>

                                        <div className={classes.inputs}>
                                            <label>
                                                Title:
                                                <input type="text" placeholder='Name...' value={state.name} name='name' onChange={handleState} />

                                            </label>

                                            <label>
                                                Title for Description 1:
                                                <input type="text" placeholder='Title 1...' value={state.descTitle1} name='descTitle1' onChange={handleState} />

                                            </label>

                                            <label>
                                                Title for Description 2:
                                                <input type="text" placeholder='Title 2...' value={state.descTitle2} name='descTitle2' onChange={handleState} />

                                            </label>












                                        </div>

                                        <h3 className={classes.scripTitle}>Property descriptions:</h3>

                                        <textarea className={classes.scrip} placeholder='Description 1...' value={state.desc1} name='desc1' onChange={handleState} />

                                        <textarea className={classes.scrip} placeholder='Description 2...' value={state.desc2} name='desc2' onChange={handleState} />


                                        <div className={classes.type}>
                                            <label>Type:</label>
                                            <div className={classes.radio}>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="buildType"
                                                        value="residential"
                                                        checked={selectedType === 'residential'}
                                                        onChange={handleTypeChange}
                                                    />
                                                    Residential
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="buildType"
                                                        value="commercial"
                                                        checked={selectedType === 'commercial'}
                                                        onChange={handleTypeChange}
                                                    />
                                                    Commercial
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="buildType"
                                                        value="leisure"
                                                        checked={selectedType === 'leisure'}
                                                        onChange={handleTypeChange}
                                                    />
                                                    Leisure
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="buildType"
                                                        value="concept"
                                                        checked={selectedType === 'concept'}
                                                        onChange={handleTypeChange}
                                                    />
                                                    Concept
                                                </label>



                                            </div>

                                        </div>

                                        <h3 className={classes.scripTitle}>Upload images:</h3>



                                        <div className={classes.types2}>


                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo">Main Image </label>
                                                <input
                                                    id="photo"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                />


                                                {photo && <img src={URL.createObjectURL(photo)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo2">Sub Image 1 </label>
                                                {photo2 !== "" && <div onClick={() => { setPhoto2("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo2"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto2(e.target.files[0])}
                                                />


                                                {photo2 && <img src={URL.createObjectURL(photo2)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>


                                            <div className={classes.upload}>
                                                <label className={classes.btnUpload2} htmlFor="photo3">Sub Image 2 </label>
                                                {photo3 !== "" && <div onClick={() => { setPhoto3("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo3"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto3(e.target.files[0])}
                                                />


                                                {photo3 && <img src={URL.createObjectURL(photo3)} style={{ width: "80px", height: "80px" }} alt="Uploaded" />}
                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo5">Sub Image 3 </label>
                                                {photo5 !== "" && <div onClick={() => { setPhoto5("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo5"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto5(e.target.files[0])}
                                                />


                                                {photo5 && <img src={URL.createObjectURL(photo5)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo6">Sub Image 4 </label>
                                                {photo6 !== "" && <div onClick={() => { setPhoto6("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo6"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto6(e.target.files[0])}
                                                />


                                                {photo6 && <img src={URL.createObjectURL(photo6)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo7">Sub Image 5 </label>
                                                {photo7 !== "" && <div onClick={() => { setPhoto7("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo7"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto7(e.target.files[0])}
                                                />


                                                {photo7 && <img src={URL.createObjectURL(photo7)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo8">Sub Image 6 </label>
                                                {photo8 !== "" && <div onClick={() => { setPhoto8("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo8"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto8(e.target.files[0])}
                                                />


                                                {photo8 && <img src={URL.createObjectURL(photo8)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>


                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo9">Sub Image 7 </label>
                                                {photo9 !== "" && <div onClick={() => { setPhoto9("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo9"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto9(e.target.files[0])}
                                                />


                                                {photo9 && <img src={URL.createObjectURL(photo9)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo10">Sub Image 8 </label>
                                                {photo10 !== "" && < div onClick={() => { setPhoto10("") }} className={classes.btnUpload}>Empty Img</div>}
                                                <input
                                                    id="photo10"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto10(e.target.files[0])}
                                                />


                                                {photo10 && <img src={URL.createObjectURL(photo10)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>



                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo13">Thumb Image </label>

                                                <input
                                                    id="photo13"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto13(e.target.files[0])}
                                                />


                                                {photo13 && <img src={URL.createObjectURL(photo13)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>



                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photos">Gallery Images  </label>
                                                <input
                                                    id="photos"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    onChange={handleUploadPhoto}
                                                />

                                               

                                                    <DragDropContext onDragEnd={onDragEnd} >
                                                        <Droppable droppableId="panelItems">
                                                            {(provided) => (
                                                                <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                                                    {photos?.map((photo, index) => (

                                                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                                            {(provided) => (

                                                                                <div className={classes.keyword} ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps} key={index}>

                                                                                    <AiOutlineClose onClick={() => {
                                                                                        deletePhoto(photo)
                                                                                    }} className={classes.removeIcon} />
                                                                                    <img
                                                                                        src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://kakushinapi.onrender.com/images/${photo}`}
                                                                                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                                        alt={`Uploaded ${index + 1}`}
                                                                                    />

                                                                                </div>
                                                                            )}



                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>

                                                
                                            </div>

                                        </div>









                                        <button className={classes.formButton2} type="submit">Create Property</button>
                                    </form>



                                </div>
                            </div>}
                            {activeTab === 'tab2' && <div className={classes.listPropertyForm} >
                                <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                    <h2>Create Press Release</h2>
                                    <form onSubmit={handleListProperty2}>

                                        <div className={classes.inputs}>
                                            <label>
                                                Title:
                                                <input type="text" placeholder='Name...' value={state2.pressTitle} name='pressTitle' onChange={handleState2} />

                                            </label>
                                            <label>
                                                Date:
                                                <input type="text" placeholder='Name...' value={state2.descTitle1} name='date' onChange={handleState2} />

                                            </label>


                                            <textarea className={classes.scrip} placeholder='Description...' value={state2.desc} name='desc' onChange={handleState2} />



                                            <label>
                                                External Source (Full URL including https://):
                                                <input type="text" placeholder='Name...' name='externalLink' value={state2.externalLink} onChange={handleState2} />

                                            </label>












                                        </div>


                                        <div className={classes.types2}>


                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo11">Cover Image </label>
                                                <input
                                                    id="photo11"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto11(e.target.files[0])}
                                                />


                                                {photo11 && <img src={URL.createObjectURL(photo11)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                        </div>














                                        <button className={classes.formButton2} type="submit">Create Press Item</button>
                                    </form>



                                </div>
                            </div>}
                            {activeTab === 'tab3' && <div className={classes.listPropertyForm} >
                                <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                    <h2>Create Blog Entry</h2>
                                    <form onSubmit={handleListProperty3}>

                                        <div className={classes.inputs}>
                                            <label>
                                                Title:
                                                <input type="text" placeholder='Name...' value={state4.title} name='title' onChange={handleState4} />

                                            </label>
                                            <label>
                                                Author:
                                                <input type="text" placeholder='Author...' value={state4.author} name='author' onChange={handleState4} />

                                            </label>

                                            <label>
                                                Strap Line:
                                                <input type="text" placeholder='Strapline...' value={state4.strap} name='strap' onChange={handleState4} />

                                            </label>





                                            <textarea className={classes.scrip} placeholder='Description...' value={state4.article} name='article' onChange={handleState4} />















                                        </div>





                                        <div className={classes.types2}>


                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo12">Cover Image </label>
                                                <input
                                                    id="photo12"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto12(e.target.files[0])}
                                                />


                                                {photo12 && <img src={URL.createObjectURL(photo12)} style={{ width: "75px", height: "75px" }} alt="Uploaded" />}

                                            </div>

                                        </div>














                                        <button className={classes.formButton2} type="submit">Create Blog</button>
                                    </form>



                                </div>
                            </div>}
                            {/* Add more tab content as needed */}
                        </div>





                        <h5 className={classes.claimer}>Employee Dashboard and associated website are property of Kakushin Ltd. 2024 </h5>
                    </>)}

                    {showForm3 && (


                        <Modal
                            isOpen={showForm3}
                            onRequestClose={() => setShowForm3(false)}
                            contentLabel="Edit User Modal"
                            className="modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: "999999999999999999999999999999999999",
                                },
                                content: {
                                    width: '55%',
                                    height: '80vh',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: "0",
                                    padding: "0",
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: "#2d2c2c",
                                    border: "0",
                                    borderRadius: "20px",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch", // For iOS Safari
                                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization

                                },
                            }}
                        >



                            <AiOutlineClose className={classes.removeIcon} onClick={() => { setShowForm3(false) }} />


                            <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                <h2>Edit User</h2>
                                <form onSubmit={handleSubmit}>

                                    <div className={classes.inputs}>
                                        <label>
                                            Username:
                                            <input type="text" name="username" value={state3.username} placeholder='Username' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            Email:
                                            <input type="email" name="email" value={state3.email} placeholder='Email' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            NEW PASSWORD:
                                            <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />
                                        </label>





                                    </div>




                                    <h3 className={classes.scripTitle}>Authentication</h3>
                                    <p className={classes.passMessage}>Please enter your <span className={classes.greenText}>CURRENT PASSWORD</span> to make any changes to your profile, including creating a new password.</p>

                                    <div className={classes.inputs}>
                                        <label>
                                            CURRENT PASSWORD:
                                            <input className={classes.currentPass} type="password" placeholder='currentPassword' onChange={handleCurrentPassword} />

                                        </label>

                                    </div>


                                    <button className={classes.formButton2} type="submit">UPDATE</button>

                                </form>

                            </div>

                        </Modal>)
                    }




                    {/* EDIT DATABASE MODALS*/}










                    <Modal
                        isOpen={showForm6}
                        onRequestClose={() => setShowForm6(false)}
                        contentLabel="Delete User Modal"
                        className="modal"

                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999",
                            },
                            content: {
                                width: '55%',
                                height: "80vh",
                                position: 'absolute',
                                margin: '0',
                                padding: '0',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

                        <AiOutlineClose onClick={() => {
                            setShowForm6(false);
                        }} className={classes.removeIcon} />

                        <AdminPress property={selectedPress} closeModal={handleCloseForm} fetchFunction={fetchAllPress} />


                    </Modal>

                    <Modal
                        isOpen={showForm7}
                        onRequestClose={() => setShowForm7(false)}
                        contentLabel="Delete User Modal"
                        className="modal"

                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999",
                            },
                            content: {
                                width: '55%',
                                height: "80vh",
                                position: 'absolute',
                                margin: '0',
                                padding: '0',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

                        <AiOutlineClose onClick={() => {
                            setShowForm7(false);
                        }} className={classes.removeIcon} />

                        <AdminBlog property={selectedBlog} closeModal={handleCloseForm} fetchFunction={fetchAllBlogs} />


                    </Modal>


                    {/*  DELETE MODALS */}


                    <Modal
                        isOpen={showForm}
                        onRequestClose={() => setShowForm(false)}
                        contentLabel="Delete User Modal"
                        className="modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999999999999999999999999999999999999",
                            },
                            content: {
                                width: '50%',
                                height: '200px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

<AiOutlineClose onClick={() => {
                                setShowForm(false)
                            }} className={classes.removeIcon} />
                        <div className={classes.delWrapper}>
                           
                            <h2>Are you sure you want to delete this property?</h2>

                            <div className={classes.btnDels}>
                                <button className={classes.btnDelete2} onClick={confirmDelete}>Delete</button>
                                <button className={classes.btnEdit2} onClick={() => setShowForm(false)}>Cancel</button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={showForm4}
                        onRequestClose={() => setShowForm4(false)}
                        contentLabel="Delete User Modal"
                        className="modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999999999999999999999999999999999999",
                            },
                            content: {
                                width: '50%',
                                height: '200px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >
                         <AiOutlineClose onClick={() => {
                                setShowForm4(false)
                            }} className={classes.removeIcon} />
                        <div className={classes.delWrapper}>
                           
                            <h2>Are you sure you want to delete this press item?</h2>

                            <div className={classes.btnDels}>
                                <button className={classes.btnDelete2} onClick={confirmDelete2}>Delete</button>
                                <button className={classes.btnEdit2} onClick={() => setShowForm4(false)}>Cancel</button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={showForm5}
                        onRequestClose={() => setShowForm5(false)}
                        contentLabel="Delete User Modal"
                        className="modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999999999999999999999999999999999999",
                            },
                            content: {
                                width: '50%',
                                height: '200px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >
                         <AiOutlineClose onClick={() => {
                                setShowForm5(false)
                            }} className={classes.removeIcon} />
                        <div className={classes.delWrapper}>
                           
                            <h2>Are you sure you want to delete this blog?</h2>

                            <div className={classes.btnDels}>
                                <button className={classes.btnDelete2} onClick={confirmDelete3}>Delete</button>
                                <button className={classes.btnEdit2} onClick={() => setShowForm5(false)}>Cancel</button>
                            </div>
                        </div>
                    </Modal>









                </div>) : (<div className={classes.wrapper2}>

                    <div className={classes.topbar}>
                        <div className={classes.section}>
                            <img alt="logo" src={PF + "knobg.png"} />
                            <h1>Kakushin</h1>

                        </div>

                    </div>

                    <h2>Please sign in to use the Kakushin Employee Dashboard. Click <a className={classes.here} href="/admin-login">HERE</a></h2>


                </div>


            )}
        </div>
    )
}

export default Admin