
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import AboutKakushin from './pages/AboutKakushin/AboutKakushin';
import { useEffect, useState } from 'react';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact';
import WhatWeDo from './pages/WhatWeDo/WhatWeDo';
import WhereWeWork from './pages/WhereWeWork/WhereWeWork';

import OurEthos from './pages/OurEthos/OurEthos';
import Press from './pages/Press/Press';
import CaseStudy from './pages/CaseStudy/CaseStudy';
import Blog from './pages/Blog/Blog';
import Portfolio from './pages/Portfolio/Portfolio';
import PropertyDetail from './pages/PropertyDetail/PropertyDetail';
import Admin from './pages/Admin/Admin';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { request } from '../src/util/fetchAPI';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';


function App() {


  const preloadedImages = [];

  //handle website

  const [website, setWebsite] = useState([]);

  const fetchWebsite = async () => {
    const data = await request(`/website/getAll`, 'GET');
    setWebsite(data);
    console.log(data);

  };


  useEffect(() => {

    fetchWebsite();

  }, [])



  useEffect(() => {


    if (website.length > 0) {
      console.log(website[0].homeTitle)


    }



  }, [website])




  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
    preloadedImages.push(img);
  };


  const [blob, setBlob] = useState("")
  const [og, setOg] = useState("")



  useEffect(() => {
    // Update the title when the component mounts

    if (website.length > 0) {
      document.title = website[0].homeTitle + " | " + website[0].homeStrap;


  
   
      setBlob(website[0].logoImg)
      setOg(website[0].ogImg)




      // Set the Open Graph (og) meta tags



  

      //preload images

      preloadImage(website[0].logoImg)



      preloadImage(website[0].ethosCoverImg)
      preloadImage(website[0].aboutCoverImg)
      preloadImage(website[0].weDoCoverImg)
      preloadImage(website[0].weWorkCoverImg)
      preloadImage(website[0].ethosBottomImg)
      preloadImage(website[0].aboutBottomImg)
      preloadImage(website[0].weDoBottomImg)
      preloadImage(website[0].weWorkCoverImg)

      preloadImage(website[0].contactImg)









    }


  }, [website])



  //handle properties



  const [allProperties, setAllProperties] = useState([])



  const fetchAllProperties = async () => {
    const data = await request(`/portfolio/getAll`, 'GET');
    setAllProperties(data);
    console.log(data);

  };


  useEffect(() => {

    fetchAllProperties();

  }, [])


  const [allEvents, setAllEvents] = useState({})


  useEffect(() => {
    const fetchAllEvents = async () => {
      const data = await request(`/blog/getAll`, 'GET');



      setAllEvents(data);
    }


    fetchAllEvents();




  }, []);


  useEffect(() => {

    if (allEvents !== undefined) {
      console.log(Object.values(allEvents));
    }

  }, [allEvents])






  return (
    <>




      {website.length > 0 ? (

        <ScrollToTop>





          <Routes>
            <Route path="/" element={

              <Home DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} homeStrap={website[0].homeStrap} homeTitle={website[0].homeTitle} descTitle={website[0].homeDescTitle} introImg={website[0].homeIntroImg} topImgs={website[0].homeImgs} bottomImg={website[0].homePortfolioImg} desc={website[0].homeDesc} readMore={website[0].homeReadMore} />


            } />

            <Route path="/portfolio" element={
              <Portfolio DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} img1={website[0].portfolioImg1} img2={website[0].portfolioImg2} img3={website[0].portfolioImg3} properties={allProperties} />
            } />


            <Route path="/about-kakushin" element={
              <AboutKakushin DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} topImg={website[0].aboutCoverImg} bottomImg={website[0].aboutBottomImg} title={website[0].aboutTitle} descTitle={website[0].aboutDescTitle} readMore={website[0].aboutReadMore} desc={website[0].aboutDesc} />
            } />





            <Route path="/gallery" element={
              <Gallery DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} photos={website[0].galleryImgs} strap={website[0].galleryQuote} bottomImg={website[0].galleryBottomImg} />
            } />

            <Route path="/contact" element={
              <Contact DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} desc={website[0].contactDesc} img={website[0].contactImg} message={website[0].contactMsg} address={website[0].contactAddress} area={website[0].contactAddressArea} city={website[0].contactAddressCity} postcode={website[0].contactAddressPostcode} days={website[0].contactOpeningDays} hours={website[0].contactOpeningTimes} phone={website[0].footerTelephone} email={website[0].footerEmail} />
            } />


            <Route path="/what-we-do" element={
              <WhatWeDo DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} title1={website[0].weDoDescTitle1} title2={website[0].weDoDescTitle2} title3={website[0].weDoDescTitle3} title4={website[0].weDoDescTitle4} desc1={website[0].weDoDesc1} desc2={website[0].weDoDesc2} desc3={website[0].weDoDesc3} desc4={website[0].weDoDesc4} img1={website[0].weDoImg1} img2={website[0].weDoImg2} img3={website[0].weDoImg3} img4={website[0].weDoImg4} bottomImg={website[0].weDoBottomImg} topImg={website[0].weDoCoverImg} mainTitle={website[0].weDoTitle} />
            } />

            <Route path="/where-we-work" element={
              <WhereWeWork DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} title={website[0].weWorkTitle} descTitle={website[0].weWorkDescTitle} readMore={website[0].weWorkReadMore} desc={website[0].weWorkDesc} topImg={website[0].weWorkCoverImg} bottomImg={website[0].weWorkBottomImg} introImg={website[0].weWorkIntroImg} />
            } />





            <Route path="/our-ethos" element={
              <OurEthos DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} introImg={website[0].ethosIntroImg} topImg={website[0].ethosCoverImg} bottomImg={website[0].ethosBottomImg} title={website[0].ethosTitle} desc={website[0].ethosDesc} descTitle={website[0].ethosDescTitle} />
            } />

            <Route path="/press" element={
              <Press />
            } />


            <Route path="/property-detail/:id" element={
              <PropertyDetail DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} />
            } />




            <Route path="/case-study" element={
              <CaseStudy DBnumber={website[0].footerTelephone} DBemail={website[0].footerEmail} DBlogo={website[0].logoImg} topImg={website[0].caseStudiesCoverImg} bottomImg={website[0].caseStudiesBottomImg} title={website[0].caseStudiesTitle} properties={allProperties} />
            } />


            <Route path="/blog" element={
              <Blog DBlogo={website[0].logoImg} newEvents={allEvents} />
            } />


            <Route path="/admin-dashboard" element={
              <Admin />
            } />




            <Route path="/admin-login" element={
              <Login />
            } />







          </Routes>




        </ScrollToTop>



      ) : (<></>)}



    </>


  );
}

export default App;
