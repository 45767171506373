import React, { useEffect, useState } from 'react'
import classes from "./galleryComponent.module.css"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const GalleryComponent = ({photos, propDetail}) => {


  const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;

  const [position, setPosition] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [degrees, setDegrees] = useState(-33);
  const [offSet, setOffSet] = useState("0")

  const [currentPhotos, setCurrentPhotos] = useState([]);


  useEffect(() => {

    if( currentPhotos.length > 2 ) {


      setOffSet(photos?.length % 2 === 0? "33vw" : "0");

    }

  }, [currentPhotos])


  useEffect(() => {

    setCurrentPhotos(photos);
    console.log("Photos:" + photos)

  }, [photos])

  const [styles, setStyles] = useState({
    transform: 'translateX(0px)'
  });

  const [styles2, setStyles2] = useState({
    transform: 'translateX(0px)'
  });



  useEffect(() => {

    const compare = { transform: 'translateX(0px)' };

    if (styles2 !== compare) {


      const timeoutId = setTimeout(() => {
        setStyles2({
          transform: 'translateX(0px)'
        });
      }, 1000);

      // Clear the timeout after 500 milliseconds (optional)
      setTimeout(() => clearTimeout(timeoutId), 500);

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timeoutId);

    }
  }, [styles2])



  const dummyPhotos = [

    { title: "oldbatch1.jpg" },
    { title: "oldbatch2.jpg" },
    { title: "oldbatch3.jpg" },
    { title: "oldbatch4.jpg" },
    { title: "oldbatch5.jpg" },
    { title: "oldbatch6.jpg" },
    { title: "oldbatch7.jpg" },




  ]





  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);


      console.log()
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {

    if (viewportWidth < 400) {

      setDegrees(-100)

      if(currentPhotos.length % 2 === 0) {
        setOffSet("-100vw");


      } else {
        setOffSet("0");

      }

    


    } else {

      setDegrees(-33)

      if(currentPhotos.length % 2 === 0 && currentPhotos.length > 2 ) {
        setOffSet("33vw");


      } else {
        setOffSet("0");

      }



    }

  }, [viewportWidth, currentPhotos]);









  const rightFunc = () => {


    if (position < ((currentPhotos.length / 2)) && currentPhotos.length >= 5 ) {
      setPosition(position + 1)
      console.log("This is the position:" + position + "and this is the current photos length:" + currentPhotos.length)
  

  

    }




  }


  const leftFunc = () => {



    if (position >= 0 && currentPhotos.length  > 3) {
      setPosition(position - 1)
    }


  }

  useEffect(() => {

    const newPosition = position * degrees;




    setStyles({

      transform: `translateX(${newPosition}vw)`,
      marginLeft: offSet

    })




  }, [position, degrees, offSet])




  return (
    <>
      <div className={classes.container}>

        <div className={classes.wrapper} style={styles}>


          {currentPhotos.map((img) => (
            <div className={classes.imgDiv} key={"key " + img} >
              <img src={PF + img } style={styles2} alt="gallery" />

            </div>


          ))



          }


  






        </div>






      </div>
      <div className={classes.buttonDiv} style={ currentPhotos.length  > 8000?  {display: "flex"} : {display: "none"}}>

        <div onClick={leftFunc}><FaArrowLeft className={classes.icon}/></div>
        <div onClick={rightFunc}><FaArrowRight className={classes.icon}/></div>

      </div>
    </>
  )
}

export default React.memo(GalleryComponent)