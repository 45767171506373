import React, {useEffect, useState} from 'react'
import classes from "./caseStudy.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'
import Intro from '../../components/Intro/Intro'
import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'
import Properties from '../../components/Properties/Properties'

const CaseStudy = ({topImg, bottomImg, title, DBlogo, properties,  DBemail,  DBnumber}) => {


  const [concepts, setConcepts] = useState([])


  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
   
  };



  preloadImage(bottomImg);
  preloadImage(topImg)




  const handleProperties = () => {


   
    let conceptsArray = [];
  
  
    for (const object of properties) {
      // Check the type property and perform actions accordingly
      switch (object.type) {
        case 'commercial':
          // Do something for Commercial type
  
          
          
          console.log('Commercial:', object);
          break;
        case 'leisure':
          // Do something for Leisure type
          
        
          console.log('Leisure:', object);
          break;
        case 'residential':
          
          
          console.log('Residential:', object);
          break;
        case 'concept':
          conceptsArray.push(object)
          console.log('Concept:', object);
          break
        default:
          // Handle other types if needed
          console.log('Unknown type:', object);
      }
    }


    setConcepts(conceptsArray);


  }


  useEffect(() => {

    handleProperties();

  }, [])








  return (
    <div>
      <Navbar scrollEvent={true}  DBlogo={DBlogo} />
      <AboutCover  img={topImg} title={title} propDetail={true} />
      <div className={classes.coming}>

        <h1>CASE STUDIES...</h1>


        <Properties properties={concepts} />


      </div>

      <Cover  img={bottomImg} propDetail={true} />
      <Footer  DBemail={DBemail} DBnumber={DBnumber} />
    </div>
  )
}

export default CaseStudy