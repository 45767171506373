import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { request } from '../../util/fetchAPI';
import classes from "./propertyDetail.module.css";
import Navbar from '../../components/Navbar/Navbar';
import AboutCover from '../../components/AboutCover/AboutCover';
import Intro from '../../components/Intro/Intro';
import GalleryComponent from '../../components/GalleryComponent/GalleryComponent';
import Cover from '../../components/Cover/Cover';
import Footer from '../../components/Footer/Footer';
import SkinnyPicture from '../../components/SkinnyPicture/SkinnyPicture';
import SkinnyPhotos from '../../components/SkinnyPhotos/SkinnyPhotos';

const PropertyDetail = ({ DBlogo, DBemail, DBnumber }) => {
  const [currentProperty, setCurrentProperty] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false)

  const fetchProperty = async () => {
    const data = await request(`/portfolio/find/${id}`, 'GET');
    setCurrentProperty(data);
    setIsLoading(false); // Set loading to false once data is fetched
  };

  useEffect(() => {
    fetchProperty();
  }, []);

  useEffect(() => {
    setIsReady(true); // Assuming that readiness is independent of currentProperty
  }, []);

  return (
    <div className={classes.container}>
      <Navbar scrollEvent={true} DBlogo={DBlogo} />
      {!isLoading && (
        <>
          {currentProperty.mainImage && (
            <AboutCover propDetail={true} img={currentProperty.mainImage} title={currentProperty.name} />
          )}
          {currentProperty.desc1 && (
            <Intro img={{ imgTitle: currentProperty.subImage1 }} title={{ titleContent: currentProperty.descTitle1 }} readMore={false} propDetail={true} text={{ t1: currentProperty.desc1 }} />
          )}
          {currentProperty.subImage7 && <SkinnyPicture propDetail={true} img={currentProperty.subImage7} />}
          {currentProperty.galleryImages?.length > 0 && <GalleryComponent propDetail={true} photos={currentProperty.galleryImages} />}
          {currentProperty.desc2 && (
            <Intro img={{ imgTitle: currentProperty.subImage2 }} title={{ titleContent: currentProperty.descTitle2 }} readMore={false} propDetail={true} reverse={{ flexDirection: "row-reverse" }} text={{ t1: currentProperty.desc2 }} />
          )}
          {currentProperty.subImage3 && currentProperty.subImage4 && (
            <SkinnyPhotos propDetail={true} img1={currentProperty.subImage3} img2={currentProperty.subImage4} />
          )}
          {currentProperty.subImage6 && <SkinnyPicture propDetail={true} img={currentProperty.subImage6} />}
          {currentProperty.subImage5 && <Cover propDetail={true} img={currentProperty.subImage5} />}
          <Footer DBnumber={DBnumber} DBemail={DBemail} /> {/* Render Footer regardless of loading state */}
        </>
      )}
    </div>
  );
};

export default React.memo(PropertyDetail);

