import React from 'react'
import classes from "./blog.module.css"
import Navbar from '../../components/Navbar/Navbar'
import BlogMain from '../../components/BlogMain/BlogMain'

const Blog = ({DBlogo, newEvents}) => {
  return (
    <div>
      <Navbar DBlogo={DBlogo}/>
      <BlogMain  newEvents={newEvents} />
      
      </div>
  )
}

export default Blog