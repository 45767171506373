import React from 'react'
import classes from "./skinnyPicture.module.css"

const SkinnyPicture = ({propDetail, img}) => {

    const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <img src={PF + img} alt="The homestead" />
            </div>
        </div>
    )
}

export default React.memo(SkinnyPicture)