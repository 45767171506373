import React from 'react'
import classes from "./cover.module.css"
import { FaArrowDown } from 'react-icons/fa';

        const Cover = React.forwardRef (({img, title, propDetail, arrow, id}, ref) => {


          





    const PF = propDetail? "https://kakushinapi.onrender.com/images/" : process.env.REACT_APP_PUBLIC_FOLDER;
  return (
   
        
        
        
        
        <div className={classes.container} id={id} ref={ref}>

        <div className={classes.abso}   >
            <h3 >{title}</h3>
        </div>

        { arrow && (
        <div className={classes.scrollIcon}><FaArrowDown/></div>)}
        <img src={PF + img} alt="Beautiful view of one of properties..."/>


    </div>
    
 
  )
});

export default Cover