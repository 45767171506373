import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import classes from "./navbar.module.css"
import Dropdown from '../Dropdown/Dropdown'
import { request } from '../../util/fetchAPI'
import { Link, useNavigate } from 'react-router-dom'

const Navbar = ({ scrollEvent = false, DBlogo }) => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [fontColor, setFontColor] = useState(scrollEvent ? "#7C7267" : "rgb(46, 51, 25)")
  const [currentLogo, setCurrentLogo] = useState("knobg.png")
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);


  const navigate = useNavigate()

  const [stylesForCurrentRef, setStylesForCurrentRef] = useState({
    color: "#ffdd40",
  })

  const [website, setWebsite] = useState([]);
  const [logo, setLogo] = useState()


  const triggerFunction = () => {
    setMenuOpen(false)
  }




  const fetchWebsite = async () => {
    const data = await request(`/website/getAll`, 'GET');
    setWebsite(data);
    console.log(data);

  };


  useEffect(() => {

    fetchWebsite();

  }, [])



  useEffect(() => {

    setCurrentLogo(DBlogo)

  }, [])





  const location = useLocation();
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);

  const [currentRef, setCurrentRef] = useState(null);
  const [currentURL, setCurrentURL] = useState(window.location.href);



  useEffect(() => {


    if (currentURL.includes('blog')) {
      setCurrentRef(div8Ref);
    } else if (currentURL.includes('case-study')) {
      setCurrentRef(div7Ref);
    } else if (currentURL.includes('contact')) {
      setCurrentRef(div9Ref);
    } else if (currentURL.includes('gallery')) {
      setCurrentRef(div6Ref);
    } else if (currentURL.includes('our-ethos')) {
      setCurrentRef(div4Ref);
    } else if (currentURL.includes('portfolio')) {
      setCurrentRef(div2Ref);
    } else if (currentURL.includes('about-kakushin') ||
      currentURL.includes('about-pauline') ||
      currentURL.includes('what-we-do') ||
      currentURL.includes('where-we-work') ||
      currentURL.includes('our-studio')) {


      setCurrentRef(div3Ref);


    } else if (currentURL.includes('press')) {
      setCurrentRef(div5Ref);
    } else {
      setCurrentRef(div1Ref);
    }
  }, [currentURL]);


  const [styles, setStyles] = useState({
    borderBottom: '0.1px solid transparent',
    backgroundColor: 'transparent',
    zIndex: '9999'
  });




  const listenScrollEvent = () => {
    if (window.scrollY > 30) {

      setFontColor("white");

      setStyles({
        borderBottom: '0.15px solid transparent',
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
        zIndex: '9999999',
        backgroundColor: '#7C7267',

      });
    } else {

      setFontColor('white');

      setStyles({
        borderBottom: '0.1px solid transparent',
        backgroundColor: 'transparent',
        boxShadow: "0px 0px 0px",
        zIndex: '9999',

      });
    }
  };




  useEffect(() => {

    setStyles(scrollEvent ? {
      borderBottom: '0.1px solid transparent',
      backgroundColor: 'transparent',
      zIndex: '9999',
      boxShadow: "0px 0px 0px",

    } : {
      borderBottom: '0.15px solid transparent',
      zIndex: '9999999',
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
      backgroundColor: '#7C7267'

    })

    setFontColor('white')




  }, [scrollEvent])




  useEffect(() => {


    if (scrollEvent === true) {


      window.addEventListener('scroll', listenScrollEvent);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', listenScrollEvent);
      };



    }




  }, [scrollEvent]);







  const PF = "https://kakushinapi.onrender.com/images/";



  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };




  const toggleMenu1 = () => {
    setOpen1(!open1);
    setOpen2(false);
  };

  const toggleMenu2 = () => {
    setOpen2(!open2);
    setOpen1(false);
  };

  return (

    <div className={classes.outerContainer}>
      <div className={classes.container} style={styles}>

        <a href="/" className={classes.logo} >
          <img src={PF + currentLogo} alt="logo" />
        </a>
        <nav className={classes.wrapper}  >

          <div className={`${classes.menu} ${isMenuOpen ? classes.open : ''}`} >
            <Link ref={div1Ref} to="/" style={{ color: fontColor }}>HOME</Link>
            <div ref={div2Ref} style={{ color: fontColor }} onClick={toggleMenu1}>
              <Dropdown
                title1={"PORTFOLIO"}
                fontColor={fontColor}
                isOpen={open1}
                propFunction={triggerFunction}
                dropdownObject={[
                  { title: "RESIDENTIAL", link: "/portfolio#residential" },
                  { title: "COMMERCIAL", link: "/portfolio#commercial" },
                  { title: "LEISURE", link: "/portfolio#leisure" }
                ]}
              />
            </div>
            <div ref={div3Ref} style={currentRef === div3Ref ? stylesForCurrentRef : { color: fontColor }} onClick={toggleMenu2}>
              <Dropdown
                title1={"ABOUT US"}
                fontColor={fontColor}
                isOpen={open2}
                dropdownObject={[
                  { title: "ABOUT KAKUSHIN", link: "/about-kakushin" },
                  { title: "WHAT WE DO", link: "/what-we-do" },
                  { title: "WHERE WE WORK", link: "/where-we-work" }
                ]}
              />
            </div>
            <Link ref={div4Ref} style={{ color: fontColor }} to="/our-ethos">OUR ETHOS</Link>
            {/* <Link ref={div5Ref} style={{ color: fontColor }} to="/press">PRESS</Link> */}
            <Link ref={div6Ref} style={{ color: fontColor }} to="/gallery">GALLERY</Link>
            <Link ref={div7Ref} style={{ color: fontColor }} to="/case-study">CASE STUDY</Link>
             <Link ref={div8Ref} style={{ color: fontColor }} to="/blog">BLOG</Link> 
            <Link ref={div9Ref} style={{ color: fontColor }} to="/contact">CONTACT</Link>


          </div>


          <div className={classes.hamburger} onClick={toggleMenu}>
            <div style={{ backgroundColor: fontColor }} className={`${classes.line} ${isMenuOpen ? classes.open : ''}`}></div>
            <div style={{ backgroundColor: fontColor }} className={`${classes.line} ${isMenuOpen ? classes.open : ''}`}></div>
            <div style={{ backgroundColor: fontColor }} className={`${classes.line} ${isMenuOpen ? classes.open : ''}`}></div>
          </div>


        </nav>
      </div>
    </div>
  )
}

export default Navbar