import React, { useEffect, useState } from 'react'
import classes from "./Properties.module.css"
import { useNavigate } from 'react-router-dom';

const Properties = ({ properties }) => {


    const history = useNavigate();

    const navigateToPage = (path) => {
        window.scrollTo(0, 0);
        history(path);
    };


    const [isTrue, setIsTrue] = useState(false)



    useEffect(() => {

        console.log("Is true is..." + isTrue)

        if (isTrue === false) {

            const timeoutId = setTimeout(() => {
                setIsTrue(true)
            }, 200);
            return () => clearTimeout(timeoutId);

        }

    }, [isTrue])


    useEffect(() => {


        if (properties.length > 0) {


            properties.forEach(property => {


                preloadImage("https://kakushinapi.onrender.com/images/" + property.mainImage);



            });






        }






        function preloadImage(url) {
            const img = new Image();
            img.src = url;
        }

    }, [properties])







    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
    return (
        <div className={classes.container} style={isTrue? {visibility: "visible"} : {visibility: "hidden"}}>





            <div className={classes.wrapper}>



                {properties?.map((property) => (


                    <div className={classes.box} onClick={() => navigateToPage(`/property-detail/${property._id}`)}>

                        <div className={classes.boxImg}>
                            <img src={"https://kakushinapi.onrender.com/images/" + property.thumbImage} alt="Click to find out more about this property..." />
                        </div>
                        <h5>{property.name}</h5>

                        <p>


                            {property.desc1 ? (
                                `${property.desc1.split('.').slice(0, 1).join('.')}${property.desc1.split('.').length > 1 ? '...' : ''}`
                            ) : (
                                // Handle the case when event.desc is undefined or null
                                'Description not available'
                            )}



                        </p>



                    </div>



                )

                )}









            </div>




        </div>
    )
}

export default Properties