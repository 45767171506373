import React from 'react'
import classes from "./aboutKakushin.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'
import Intro from '../../components/Intro/Intro'
import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'

const AboutKakushin = ({topImg, bottomImg, title, descTitle, readMore, desc, DBlogo, DBemail, DBnumber}) => {


  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
   
  };



  preloadImage(bottomImg);
  preloadImage(topImg)

  return (
    <div>
      

<Navbar scrollEvent={true} DBlogo={DBlogo}/>
<AboutCover img={topImg} title={title} propDetail={true}/>
<Intro img={{imgTitle: topImg}} title={{titleContent: descTitle}} text={{t1: desc, t3: readMore}} propDetail={true} readMore={true}/>
<Cover img={bottomImg}  propDetail={true}/>
<Footer DBemail={DBemail} DBnumber={DBnumber}/>


      </div>
  )
}

export default AboutKakushin