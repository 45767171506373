import React from 'react'
import classes from "./gallery.module.css"
import Navbar from '../../components/Navbar/Navbar'
import AboutCover from '../../components/AboutCover/AboutCover'
import Intro from '../../components/Intro/Intro'
import Cover from '../../components/Cover/Cover'
import Footer from '../../components/Footer/Footer'
import GalleryMain from '../../components/GalleryMain/GalleryMain'

const Gallery = ({photos, strap, bottomImg,  DBlogo, DBemail, DBnumber}) => {
  return (
    <div>
      <Navbar scrollEvent={false}  DBlogo={DBlogo}/>
      <GalleryMain photos={photos} strap={strap}/>
      <Cover  img={bottomImg} propDetail={true}/>
      <Footer  DBemail={DBemail} DBnumber={DBnumber}/>
    </div>
  )
}

export default Gallery